var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from "vue-property-decorator";
let Acortar = class Acortar extends Vue {
    constructor() {
        super(...arguments);
        this.show = false;
        this.text = this.value;
    }
    toggle() {
        this.show = !this.show;
    }
    get isLong() {
        return this.text.length >= this.size;
    }
    get isShort() {
        return this.text.length <= this.size;
    }
    get shortText() {
        return this.text.substring(0, this.size) + '...';
    }
    get longText() {
        return this.text;
    }
};
__decorate([
    Prop({ type: Number, default: 200 })
], Acortar.prototype, "size", void 0);
__decorate([
    Prop({ type: String, required: true })
], Acortar.prototype, "value", void 0);
Acortar = __decorate([
    Component({})
], Acortar);
export default Acortar;
