var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from "vue-property-decorator";
import Timer from './Aside/Timer.vue';
import Detalle from './Aside/Detalle.vue';
import Politicas from './Aside/Politicas.vue';
import Liquidacion from './Aside/Liquidacion.vue';
import MasInformacion from './Aside/MasInformacion.vue';
import Legales from './Form/Legales.vue';
import Contacto from './Form/Contacto.vue';
import Sucursal from './Form/Sucursal.vue';
import Pasajeros from './Form/Pasajeros.vue';
import Facturacion from './Form/Facturacion.vue';
import FormasDePago from '../../../../mkte_formas_pago/js/components/FormularioOnline.vue';
let ReservaFormulario = class ReservaFormulario extends Vue {
    constructor() {
        super(...arguments);
        this.ok = false;
        this.forma = null;
        this.preloader = false;
        this.pasajerosOK = false;
    }
    get formAction() {
        return `${this.url}/services/actividades/reservar_action/${this.tarifaId}`;
    }
    get formasPagoUrl() {
        return `${this.url}/services/actividades/formas_pago/${this.tarifaId}`;
    }
    setForma(forma) {
        this.forma = forma;
    }
    get isValid() {
        return this.ok && this.pasajerosOK;
    }
    enviar() {
        if (this.reserva.no_reembolsable) {
            this.$confirm({
                size: "lg",
                html: true,
                title: "Confirmar la Reserva",
                content: `
<div class="clearfix" style="margin: 15px 0;">
  <i class="mdi mdi-calendar-alert text-muted pull-left" style="font-size: 70px; line-height: 60px;"></i>
  <div class="lead" style="margin: 0px 0px 0px 85px;">
    Esta tarifa es <b>NO REEMBOLSABLE</b>, por lo cual est&aacute; incurriendo en gastos por cancelaci&oacute;n. 
    Desea continuar de todos modos?</div>
</div>
`,
                okText: "Reservar",
                okType: "success",
                cancelText: "Cancelar",
                cancelType: "warning"
            }).then(() => {
                this.preloader = true;
                this.$refs.form.submit();
            }).catch(() => { });
        }
        else {
            this.preloader = true;
            this.$refs.form.submit();
        }
    }
};
__decorate([
    Prop({ type: String, required: true })
], ReservaFormulario.prototype, "url", void 0);
__decorate([
    Prop({ type: Object, required: true })
], ReservaFormulario.prototype, "reserva", void 0);
__decorate([
    Prop({ type: Number, required: true })
], ReservaFormulario.prototype, "tarifaId", void 0);
__decorate([
    Prop({ type: String, required: false, default: "" })
], ReservaFormulario.prototype, "legales", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], ReservaFormulario.prototype, "dev", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], ReservaFormulario.prototype, "sucursales", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], ReservaFormulario.prototype, "formasPago", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], ReservaFormulario.prototype, "facturacion", void 0);
__decorate([
    Prop({ type: String, required: false, default: "####-####" })
], ReservaFormulario.prototype, "telefonoFormato", void 0);
ReservaFormulario = __decorate([
    Component({
        components: {
            Timer,
            Detalle,
            Politicas,
            Liquidacion,
            MasInformacion,
            Legales,
            Contacto,
            Sucursal,
            Pasajeros,
            Facturacion,
            FormasDePago
        }
    })
], ReservaFormulario);
export default ReservaFormulario;
