<script>
export default {
  props: {
    master: { type: [Object, Boolean], required: true, default: false }
  },
  data() {
    return {
      sel_cat: false,

      tarjeta_id: false,
      banco_id: false,
      cuota_id: false,
      forma_id: false,

      pasarela_id: false,
      select: false
    };
  },
  watch: {
    tarjeta_id(id) {
      this.banco_id = false;
      if (id) {
        this.forma_id = false;
      }
    },
    banco_id(id) {
      this.cuota_id = false;
      if (id) {
        this.forma_id = false;
      }
    },
    forma_id(id) {
      this.forma_id = id;
      if (!id) {
        this.select = false;
        this.$emit("seleccionar", this.select);
      }
    }
  },
  methods: {
    formatMonto(monto) {
      return monto.toFixed(2).replace(/./g, function(c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      });
    },
    setForma(
      categoria_id,
      id,
      nombre,
      sub_item,
      moneda,
      monto,
      plataforma,
      pasarela_id
    ) {
      this.forma_id = id;

      this.tarjeta_id = false;
      this.banco_id = false;
      this.cuota_id = false;

      this.pasarela_id = pasarela_id;

      this.select = {
        id: id,
        nombre: nombre,
        sub_item: sub_item,
        moneda: moneda,
        monto: monto,
        format: this.formatMonto(monto),
        categoria_id: categoria_id,
        plataforma: plataforma,
        pasarela_id: pasarela_id
      };

      this.$emit("seleccionar", this.select);
    },
    setCuota(forma, cuota, nombre, sub_item, moneda, monto, banco, tarjeta) {
      this.forma_id = forma;
      this.cuota_id = cuota;

      this.pasarela_id = false;

      this.select = {
        id: forma,
        nombre: nombre,
        sub_item: sub_item,
        moneda: moneda,
        monto: monto,
        format: this.formatMonto(monto),
        categoria_id: 1,
        plataforma: "categoria",
        banco: this.creditoBanco,
        tarjeta: this.creditoTarjeta
      };

      this.$emit("seleccionar", this.select);
    },
    clear() {
      /* Resetear parametros */
      this.forma_id = false;
      this.cuota_id = false;
      this.banco_id = false;
      this.tarjeta_id = false;

      this.$emit("seleccionar", false);
    },
    toggle(id) {
      if (this.sel_cat) {
        if (this.sel_cat === id) {
          this.clear();
        } else {
          this.sel_cat = id;
        }
      } else {
        this.sel_cat = id;
      }
    }
  },
  computed: {
    preloader() {
      return this.master ? false : true;
    },
    valido() {
      return this.master.categorias || this.master.credito ? true : false;
    },
    categorias() {
      return this.master.categorias;
    },
    credito() {
      return this.master.credito;
    },
    creditoTarjetas() {
      return this.credito.tarjetas;
    },
    creditoBancos() {
      if (this.tarjeta_id) {
        let tarjeta = _.find(this.creditoTarjetas, ["id", this.tarjeta_id]);
        return tarjeta.bancos;
      }
      return false;
    },
    creditoFormas() {
      if (this.banco_id) {
        let banco = _.find(this.creditoBancos, ["id", this.banco_id]);
        let formas = banco.formas;
        return formas;
      }
      return false;
    },
    creditoBanco() {
      if (this.banco_id) {
        let banco = _.find(this.creditoBancos, ["id", this.banco_id]);

        delete banco.formas;

        return banco;
      }
      return false;
    },
    creditoTarjeta() {
      if (this.tarjeta_id) {
        let tarjeta = _.find(this.creditoTarjetas, ["id", this.tarjeta_id]);

        delete tarjeta.bancos;

        return tarjeta;
      }
      return false;
    },
    creditoCuota() {
      if (this.cuota_id) {
        let forma = _.find(this.creditoFormas, ["id", this.forma_id]);
        let cuota = _.find(forma.cuotas, ["id", this.cuota_id]);
        return cuota;
      }
      return false;
    },
    formaTexto() {
      if (this.select !== false) {
        return this.select.nombre + ": " + this.select.sub_item;
      }
      return false;
    },
    nombre() {
      if (this.select !== false) {
        return this.select.nombre;
      }
      return false;
    },
    concepto() {
      if (this.select !== false) {
        return this.select.sub_item;
      }
      return false;
    },
    monto() {
      if (this.select !== false) {
        return this.select.format;
      }
      return false;
    }
  }
};
</script>

<template>
  <div id="formas">
    <div class="page-header" v-if="valido">
      <h3>
        <i class="fa fa-credit-card"></i>
        Medio de Pago
      </h3>
    </div>

    <div id="formas_formulario">
      <input type="hidden" name="fpo_categoria" v-if="sel_cat" v-bind:value="sel_cat" />
      <input type="hidden" name="fpo_forma_id" v-if="forma_id" v-bind:value="forma_id" />

      <!-- Pasarela: -->
      <input type="hidden" name="fpo_pasarela_id" v-if="pasarela_id" v-bind:value="pasarela_id" />

      <!-- Cuota: -->
      <input type="hidden" name="fpo_id_tarjeta" v-if="tarjeta_id" v-bind:value="tarjeta_id" />
      <input type="hidden" name="fpo_id_banco" v-if="banco_id" v-bind:value="banco_id" />
      <input type="hidden" name="fpo_cuota_id" v-if="cuota_id" v-bind:value="cuota_id" />

      <!-- Cuota: -->
      <input type="hidden" name="fpo_cuotas" v-if="creditoCuota" v-bind:value="creditoCuota.cuota" />
      <input
        type="hidden"
        name="fpo_interes"
        v-if="creditoCuota"
        v-bind:value="creditoCuota.interes_raw"
      />
      <input type="hidden" name="fpo_moneda" v-if="creditoCuota" v-bind:value="creditoCuota.moneda" />
      <input
        type="hidden"
        name="fpo_monto_cuota"
        v-if="creditoCuota"
        v-bind:value="creditoCuota.tarifa_raw"
      />
      <input
        type="hidden"
        name="fpo_monto_bruto"
        v-if="creditoCuota"
        v-bind:value="creditoCuota.tarifa_total_raw"
      />

      <!-- Texto: -->
      <input type="hidden" name="fpo_texto" v-if="formaTexto" v-bind:value="formaTexto" />

      <!-- Para la Reserva: -->
      <input type="hidden" name="fpo_forma_nombre" v-bind:value="nombre" />
      <input type="hidden" name="fpo_concepto" v-bind:value="concepto" />
      <input type="hidden" name="fpo_monto" v-bind:value="monto" />

      <div class="progress" v-if="preloader">
        <div class="progress-bar progress-bar-info progress-bar-striped active"></div>
      </div>

      <div class="panel-group" id="accordion" v-else>
        <!-- Credito -->
        <div class="panel panel-default" v-if="credito">
          <div class="panel-heading">
            <h4 class="panel-title">
              <a
                class="collapsed"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#credito"
                aria-expanded="false"
                v-on:click="toggle(1)"
              >
                <i v-bind:class="credito.icono"></i>
                <span v-html="credito.nombre"></span>
              </a>
            </h4>
          </div>

          <div class="panel-collapse collapse" id="credito">
            <div class="list-group list-group-credito">
              <!-- Tarjetas -->
              <div class="list-group-item tarjetas">
                <ul class="list-inline">
                  <li
                    v-for="tarjeta in creditoTarjetas"
                    v-bind:title="tarjeta.nombre"
                    v-bind:class="(tarjeta.id == tarjeta_id) ? 'active' : false"
                  >
                    <label>
                      <input type="radio" v-bind:value="tarjeta.id" v-model="tarjeta_id" />
                      <img v-bind:src="tarjeta.img" />
                    </label>
                  </li>
                </ul>
              </div>

              <!-- Bancos -->
              <div class="list-group-item bancos" v-if="tarjeta_id">
                <div class="input-group input-group-sm">
                  <label class="input-group-addon" for>Banco</label>
                  <select class="form-control" v-model="banco_id">
                    <option v-bind:value="false">Seleccionar</option>
                    <option
                      v-for="banco in creditoBancos"
                      v-bind:value="banco.id"
                      v-html="banco.nombre"
                    ></option>
                  </select>
                </div>
              </div>

              <!-- Formas -->
              <template v-id v-for="forma in creditoFormas">
                <div class="list-group-item forma">
                  <h4 class="list-group-item-heading" v-html="forma.nombre"></h4>

                  <div class="list-group-item-text" v-if="forma.texto" v-html="forma.texto"></div>
                </div>

                <label
                  class="list-group-item cuota"
                  v-if="banco_id"
                  v-for="cuota in forma.cuotas"
                  v-bind:class="( cuota.id == cuota_id ) ? 'active' : false"
                  v-on:click="setCuota(forma.id, cuota.id, credito.nombre, cuota.sub_item, cuota.moneda, cuota.monto)"
                >
                  <div class="row row-20">
                    <div class="col-xs-12 col-sm-9" v-html="cuota.sub_item"></div>
                    <div class="col-xs-12 col-sm-3">
                      <div class="cft">
                        CFT
                        <span v-html="cuota.cft"></span>
                        <small>
                          TEA
                          <span v-html="cuota.tea"></span>
                        </small>
                      </div>
                    </div>
                  </div>
                </label>
              </template>
            </div>
          </div>
        </div>

        <!-- Formas -->
        <template v-for="(categoria, key) in categorias">
          <div class="panel panel-default" v-if="categorias.length" :key="key">
            <div class="panel-heading">
              <h4 class="panel-title">
                <a
                  class="collapsed"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  v-bind:href=" '#forma_' + categoria.id + '_' + categoria.forma_id "
                  aria-expanded="false"
                  v-on:click="toggle( key + 2 )"
                >
                  <i v-bind:class="categoria.icono"></i>
                  <span v-html="categoria.nombre"></span>
                </a>
              </h4>
            </div>

            <div
              class="panel-collapse collapse"
              v-bind:id=" 'forma_' + categoria.id + '_' + categoria.forma_id "
            >
              <div class="list-group list-group-formas">
                <div
                  class="list-group-item"
                  v-for="(forma, keyF) in categoria.formas"
                  v-bind:class="( forma.id == forma_id ) ? 'active' : false"
                  :key="keyF"
                >
                  <label
                    v-on:click="setForma(categoria.id, forma.id, forma.nombre, forma.sub_item, forma.moneda, forma.monto, forma.plataforma, forma.pasarela_id)"
                  >
                    <div class="row row-20">
                      <div class="col-xs-12 col-sm-2">
                        <div
                          class="img-bg"
                          v-bind:style="{ backgroundImage: 'url(' + forma.img + ')' }"
                        ></div>
                      </div>

                      <div class="col-xs-12 col-sm-10">
                        <div class="row row-20">
                          <div class="col-xs-12 col-sm-7">
                            <h4 class="list-group-item-heading" v-html="forma.nombre"></h4>
                          </div>

                          <div class="col-xs-12 col-sm-5">
                            <div class="cargo">
                              Cargo Administrativo:
                              <span v-html="forma.cargo + '%' "></span>
                            </div>
                          </div>
                        </div>

                        <div class="list-group-item-text" v-if="forma.texto" v-html="forma.texto"></div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#formas_formulario {
  overflow: hidden;
  margin-bottom: 25px;

  > .progress {
    margin: 15px 15px;
    height: 15px;
    background-color: #656d78;
    > .progress-bar {
      width: 60%;
    }
  }

  > .panel-group {
    margin: 0;
    border: 1px solid #aab2bd;

    > .panel {
      margin: 0;
      border: 0;
      border-bottom: 1px solid #ddd;
      border-radius: 0;
      box-shadow: none;

      &:first-child {
        > .panel-heading {
          border-radius: 0;
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      > .panel-heading {
        background: none;
        color: inherit;
        font-style: normal;
        border: 0;
        padding: 0;

        > .panel-title {
          padding: 0;
          margin: 0;
          font-size: inherit;

          > a {
            color: #ffffff;
            display: block;
            padding: 15px 15px 15px 35px;
            z-index: 2;
            position: relative;
            font-size: 14px;
            background: #aab2bd;
            font-style: normal;
            font-weight: 600;
            border-radius: 0;
            text-decoration: none;

            cursor: pointer;
            > .fa {
              color: #f2f2f2;
              width: 25px;
              display: inline-block;
              text-align: center;
            }

            &:before {
              top: 15px;
              font: normal normal normal 18px/1 FontAwesome;
              left: 15px;
              width: 15px;
              color: #7d838a;
              display: inline-block;
              content: "\f0d7";
              position: absolute;
              font-size: 20px;
              text-align: center;
            }

            &.collapsed:before {
              content: "\f0d8";
            }

            &:hover:before {
              color: #656d78;
            }
          }
        }
      }

      /* Collapse */
      > .panel-collapse {
        position: relative;
        z-index: 1;

        .list-group {
          padding: 0;
          border-radius: 0;

          /* Tarjetas */
          &.list-group-credito .list-group-item.tarjetas {
            padding: 15px 10px;

            .list-inline {
              margin: 0 0 0 -5px;
              text-align: center;

              > li {
                padding: 7px 7px;

                > label {
                  margin: 0;
                  font-weight: normal;
                  position: relative;
                  cursor: pointer;

                  > input {
                    display: none;
                  }

                  > img {
                    position: relative;
                    z-index: 1;
                    height: 50px;
                    background: #fff;
                    border-radius: 3px;
                    box-shadow: 2px 2px 8px rgba(51, 51, 51, 0.3);
                  }
                }

                &.active > label {
                  > img {
                    box-shadow: 0px 4px 18px rgba(51, 51, 51, 0.85);
                  }

                  &:after {
                    display: block;
                    content: "\f00c";
                    font: normal normal normal 30px/1 FontAwesome;
                    position: absolute;
                    bottom: -8px;
                    right: -10px;
                    color: #3c763d;
                    text-shadow: 2px 1px 2px rgba(51, 51, 51, 0.3);
                    z-index: 2;
                  }
                }
              }
            }
          }

          /* Bancos */
          &.list-group-credito .list-group-item {
            &.bancos {
              padding: 15px;

              > .input-group {
                .input-group-addon {
                  border: 0;
                  padding: 5px 10px;
                  background: #aab2bd;
                  border-radius: 0;
                }
                .form-control {
                  margin: 0;
                  height: 32px;
                  box-shadow: none;
                  font-style: normal;
                  border-left: 0;
                  border-radius: 0;
                }
              }
            }

            /* Forma */
            &.forma {
              background: #fff;

              > .list-group-item-heading {
                font-size: 15px;
              }

              .list-group-item-text {
                font-size: 11px;
                color: #999;

                > p {
                  margin: 0 0 10px;
                }
              }
            }

            /* Cuotas */
            &.cuota {
              cursor: pointer;
              z-index: 1;
              display: block;
              padding: 15px 10px 10px 35px;
              position: relative;
              font-size: 13px;
              background: #eaeaea;
              font-weight: normal;
              border-radius: 0;
              margin: 0;

              &:hover {
                z-index: 2;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

                &:before {
                  color: #42763d;
                }
              }

              &.active {
                color: inherit;
                background: #e1fbd9;
                border-color: #ddd;

                &:before {
                  content: "\f046";
                  color: #3c763d;
                }
              }

              input[type="radio"] {
                display: none;
              }

              .cft {
                color: #a7a5a5;
                font-size: 16px;
                text-align: right;
                line-height: 15px;

                small {
                  font-size: 12px;
                }
              }

              &:before {
                display: inline-block;
                width: 20px;
                color: #95a095;
                position: absolute;
                left: 10px;
                bottom: 10px;
                font: normal normal normal 18px/1 FontAwesome;
                content: "\f096";
              }
            }
          }

          /* Formas */
          &.list-group-formas > .list-group-item {
            padding: 0;
            border-color: #aab2bd;
            background: #fff;
            overflow: hidden;

            &:last-child {
              margin-bottom: 0;
            }

            > label {
              display: block;
              position: relative;
              margin: 0;
              padding: 15px 10px 10px 35px;
              font-weight: normal;
              cursor: pointer;

              > input[type="radio"] {
                display: none;
              }

              &:before {
                display: inline-block;
                width: 20px;
                color: #95a095;
                position: absolute;
                left: 10px;
                top: 24px;
                font: normal normal normal 18px/1 FontAwesome;
                content: "\f096";
              }

              .img-bg {
                height: 45px;
                background: center no-repeat;
                background-size: contain;
              }

              .list-group-item-heading {
                margin: 7px 0;
                font-size: 19px;
                font-weight: 500;
              }

              .cargo {
                font-size: 14px;
                font-weight: 500;
                text-align: right;
                padding: 7px 0;
                span {
                  font-family: monospace;
                }
              }
            }

            p {
              color: #555;
              margin: 0;
              font-size: 12px;
              line-height: 16px;
            }

            &.active {
              color: inherit;
              background: #e1fbd9;
              border-color: #e1fbd9;

              .list-group-item-text {
                color: inherit;
              }

              > label:before {
                content: "\f046";
                color: #3c763d;
              }

              &:hover > label:before {
                color: #42763d;
              }
            }
          }
        }
      }
    }
  }
}
</style>