var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _ from 'lodash';
import axios from 'axios';
import Acortar from './Acortar.vue';
import { Getter } from 'vuex-class';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Notification } from 'uiv';
let EmailModal = class EmailModal extends Vue {
    constructor() {
        super(...arguments);
        this.mostrar = this.value;
        this.enviando = false;
        this.formulario = {
            asunto: '',
            mensaje: '',
            destinatario: '',
        };
        this.$notify = Notification.notify;
    }
    onMostrarChanged() {
        if (this.mostrar != this.value) {
            this.$emit("input", this.mostrar);
        }
    }
    onValueChanged() {
        if (this.mostrar != this.value) {
            this.mostrar = this.value;
        }
    }
    get producto() {
        const producto = this.resultado;
        producto.tarifa = this.getTarifa(producto);
        producto.isTarifa = (producto.tarifa &&
            _.hasIn(producto.tarifa, "rules_tarifa") &&
            producto.tarifa.rules_tarifa) ? true : false;
        return producto;
    }
    getTarifa(producto) {
        const ticket = _.head(producto.tickets);
        if (ticket) {
            const tarifa = _.head(ticket.tarifas);
            if (tarifa) {
                return tarifa;
            }
        }
        return false;
    }
    get isValid() {
        return this.formulario.asunto.length > 0 && this.formulario.mensaje.length > 0 && this.formulario.destinatario.length > 0;
    }
    async enviar(e) {
        e.preventDefault();
        this.enviando = true;
        const url = this.getURL(`/services/actividades/compartir/${this.producto.tarifa.id}`);
        const response = await axios.get(url, { params: this.formulario });
        this.mostrar = false;
        this.enviando = false;
        this.formulario = {
            asunto: '',
            mensaje: '',
            destinatario: '',
        };
        this.$notify.success({
            icon: 'fa fa-check',
            title: 'Enviar por Mail',
            content: 'Se envio correctamente.'
        });
    }
};
__decorate([
    Prop({ type: Boolean, required: true, default: false })
], EmailModal.prototype, "value", void 0);
__decorate([
    Prop({ type: Object, required: true })
], EmailModal.prototype, "resultado", void 0);
__decorate([
    Getter("getURL")
], EmailModal.prototype, "getURL", void 0);
__decorate([
    Getter("getConfig")
], EmailModal.prototype, "getConfig", void 0);
__decorate([
    Watch("mostrar")
], EmailModal.prototype, "onMostrarChanged", null);
__decorate([
    Watch("value")
], EmailModal.prototype, "onValueChanged", null);
EmailModal = __decorate([
    Component({ components: { Acortar } })
], EmailModal);
export default EmailModal;
