var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _ from "lodash";
import moment from "moment";
import localeES from "uiv/src/locale/lang/es-ES";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
const from = moment().format("YYYY-MM-DD");
const to = moment().add(3, "months").format("YYYY-MM-DD");
let Calendario = class Calendario extends Vue {
    constructor() {
        super(...arguments);
        this.id = _.uniqueId("calendario_");
        this.fecha = this.value;
        this.hasta = this.to;
        this.visible = false;
        this.localeES = localeES;
    }
    created() {
        if (this.fecha) {
            if (moment(this.fecha) > moment(this.from)) {
                this.hasta = moment(this.fecha).add(2, "months").format("YYYY-MM-DD");
            }
        }
    }
    onFechaChanged(fecha) {
        this.hide();
        if (fecha != this.value) {
            this.$emit("input", fecha);
        }
    }
    onFromChanged(fecha) {
        if (this.fecha) {
            if (moment(fecha) > moment(this.fecha)) {
                this.fecha = this.from;
                this.hasta = moment(fecha).add(3, "months").format("YYYY-MM-DD");
            }
        }
    }
    onValueChanged(fecha) {
        if (this.fecha != fecha) {
            this.fecha = fecha;
        }
    }
    hide() {
        this.visible = false;
    }
    mostrar() {
        this.visible = true;
    }
    toggle() {
        this.visible = !this.visible;
    }
    get locale() {
        let locale = this.localeES;
        locale.uiv.datePicker.week1 = "LUN";
        locale.uiv.datePicker.week2 = "MAR";
        locale.uiv.datePicker.week3 = "MIE";
        locale.uiv.datePicker.week4 = "JUE";
        locale.uiv.datePicker.week5 = "VIE";
        locale.uiv.datePicker.week6 = "SAB";
        locale.uiv.datePicker.week7 = "DOM";
        return locale;
    }
    get placeholder() {
        if (this.fecha) {
            return moment(this.fecha).format("DD/MM/YYYY");
        }
        return "Seleccionar";
    }
};
__decorate([
    Prop({ type: String, required: true })
], Calendario.prototype, "value", void 0);
__decorate([
    Prop({ type: String, default: () => from })
], Calendario.prototype, "from", void 0);
__decorate([
    Prop({ type: String, default: () => to })
], Calendario.prototype, "to", void 0);
__decorate([
    Watch("fecha")
], Calendario.prototype, "onFechaChanged", null);
__decorate([
    Watch("from")
], Calendario.prototype, "onFromChanged", null);
__decorate([
    Watch("value")
], Calendario.prototype, "onValueChanged", null);
Calendario = __decorate([
    Component({ components: {} })
], Calendario);
export default Calendario;
