var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
let Legales = class Legales extends Vue {
    constructor() {
        super(...arguments);
        this.ok = this.value;
        this.show = false;
    }
    onOkChanged(payload) {
        this.$emit('input', payload);
    }
};
__decorate([
    Prop({ type: String, required: true })
], Legales.prototype, "src", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], Legales.prototype, "value", void 0);
__decorate([
    Watch('ok')
], Legales.prototype, "onOkChanged", null);
Legales = __decorate([
    Component({})
], Legales);
export default Legales;
