import BuscadorVertical from './Buscador/BuscadorVertical.vue';
import BuscadorHorizontal from './Buscador/BuscadorHorizontal.vue';
import ResultadosBusqueda from './Resultados/ResultadosBusqueda.vue';
import ReservaFormulario from './Reserva/ReservaFormulario.vue';
import ConsultarFormulario from './Consulta/ConsultarFormulario.vue';
export default {
    BuscadorVertical,
    ReservaFormulario,
    ConsultarFormulario,
    BuscadorHorizontal,
    ResultadosBusqueda,
};
