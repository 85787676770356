var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Getter, Mutation, Action } from "vuex-class";
import Placeholder from "../Placeholder/Filtros/Texto.vue";
import { Vue, Component } from "vue-property-decorator";
let Texto = class Texto extends Vue {
    constructor() {
        super(...arguments);
        this.filtro = "";
    }
    enviar(e) {
        e.preventDefault();
        this.setParamNombre(this.filtro);
        this.filtrar();
    }
};
__decorate([
    Action("filtrar")
], Texto.prototype, "filtrar", void 0);
__decorate([
    Getter("isLoading")
], Texto.prototype, "isLoading", void 0);
__decorate([
    Mutation("setParamNombre")
], Texto.prototype, "setParamNombre", void 0);
Texto = __decorate([
    Component({
        components: { Placeholder },
    })
], Texto);
export default Texto;
