var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _ from "lodash";
import { Vue, Component, Prop } from "vue-property-decorator";
let Fotos = class Fotos extends Vue {
    constructor() {
        super(...arguments);
        this.ref = _.uniqueId("fotos_");
        this.index = null;
    }
    open(key) {
        this.index = key;
    }
    close() {
        this.index = null;
    }
    get items() {
        return this.src.map((foto, key) => {
            return {
                src: foto,
                description: "Foto #" + key,
            };
        });
    }
};
__decorate([
    Prop({ type: Array, required: true })
], Fotos.prototype, "src", void 0);
Fotos = __decorate([
    Component({ components: {} })
], Fotos);
export default Fotos;
