var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _ from "lodash";
import { Vue, Component, Prop } from "vue-property-decorator";
import TarifaRow from "./TarifaRow.vue";
let Tarifas = class Tarifas extends Vue {
    constructor() {
        super(...arguments);
        this.limit = 1;
    }
    get total() {
        return this.tickets.length;
    }
    get listado() {
        return _.take(this.tickets, this.limit * 2);
    }
    get isVerTodo() {
        return this.limit * 2 >= this.total;
    }
    get getRestantesTotal() {
        return this.total - this.limit * 2;
    }
    verMas() {
        this.limit++;
    }
    verMenos() {
        this.limit = 1;
    }
};
__decorate([
    Prop({ type: Array, required: true })
], Tarifas.prototype, "tickets", void 0);
Tarifas = __decorate([
    Component({ components: { TarifaRow } })
], Tarifas);
export default Tarifas;
