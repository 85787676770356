var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Pais from './Pais.vue';
import FechaNacimiento from './FechaNacimiento.vue';
import { TheMask } from 'vue-the-mask';
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
let Pasajeros = class Pasajeros extends Vue {
    constructor() {
        super(...arguments);
        this.titular = 1;
        this.errores = [];
        this.pasajeros = [];
    }
    created() {
        for (let i = 0; i < this.adultos; i++) {
            this.errores.push({ error: false });
            this.pasajeros.push({ nombre: '', apellido: '' });
        }
        if (this.menores) {
            for (let i = 0; i < this.menores; i++) {
                this.errores.push({ error: false });
                this.pasajeros.push({ nombre: '', apellido: '' });
            }
        }
    }
    onDataChanged() {
        let error = false;
        this.pasajeros.forEach((pasajero, key) => {
            this.errores[key]['error'] = pasajero.nombre !== '' && pasajero.nombre === pasajero.apellido;
            if (!this.errores[key]['error'] && this.pasajeros.length > 1) {
                const fullName = pasajero.nombre + pasajero.apellido;
                this.errores[key]['error'] = this.pasajeros.filter((pasajero) => {
                    return pasajero.nombre !== '' && fullName === pasajero.nombre + pasajero.apellido;
                }).length > 1;
            }
            if (this.errores[key]['error']) {
                error = true;
            }
        });
        this.$emit('input', !error);
    }
};
__decorate([
    Prop({ type: Boolean, required: true })
], Pasajeros.prototype, "value", void 0);
__decorate([
    Prop({ type: String, required: true })
], Pasajeros.prototype, "fecha", void 0);
__decorate([
    Prop({ type: Number, required: true })
], Pasajeros.prototype, "adultos", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 0 })
], Pasajeros.prototype, "menores", void 0);
__decorate([
    Prop({ type: Array, required: false, default: [] })
], Pasajeros.prototype, "edades", void 0);
__decorate([
    Watch('pasajeros', { deep: true })
], Pasajeros.prototype, "onDataChanged", null);
Pasajeros = __decorate([
    Component({ components: { TheMask, Pais, FechaNacimiento } })
], Pasajeros);
export default Pasajeros;
