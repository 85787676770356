var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from "vue-property-decorator";
let Politicas = class Politicas extends Vue {
    constructor() {
        super(...arguments);
        this.aclaracion = `Fecha y hora se calcula bas${String.fromCharCode(225)}ndose en la hora local del destino.`;
    }
    get panelClass() {
        if (this.politicas.reembolsable === true) {
            return 'panel-success';
        }
        else if (this.politicas.reembolsable === false) {
            return 'panel-danger';
        }
        return 'panel-warning';
    }
};
__decorate([
    Prop({ type: Object, required: true })
], Politicas.prototype, "politicas", void 0);
Politicas = __decorate([
    Component({})
], Politicas);
export default Politicas;
