var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _ from "lodash";
import { Vue, Component, Prop } from "vue-property-decorator";
let Formulario = class Formulario extends Vue {
    constructor() {
        super(...arguments);
        this.email = this.datos.email;
        this.nombre = this.datos.nombre;
        this.apellido = this.datos.apellido;
        this.telefono = this.datos.telefono;
        this.consulta = this.datos.consulta;
    }
    get ok() {
        return this.nombre.length > 0 && this.apellido.length > 0 && this.email.length > 0 && this.telefono.length > 0;
    }
    get reCaptcha() {
        return _.hasIn(this.$slots, 'default');
    }
};
__decorate([
    Prop({ type: String, required: true })
], Formulario.prototype, "url", void 0);
__decorate([
    Prop({ type: Object, required: true })
], Formulario.prototype, "datos", void 0);
Formulario = __decorate([
    Component({})
], Formulario);
export default Formulario;
