<script>
import { Btn, Modal } from "uiv";
import { TheMask } from "vue-the-mask";

export default {
  components: { Btn, Modal, TheMask },
  props: {
    url: { type: String, required: true },
    show: { type: Boolean, required: false, default: false },
    banco: { type: [Object, Boolean], required: false, default: false },
    tarjeta: { type: [Object, Boolean], required: false, default: false }
  },
  data() {
    return {
      openHelpCodigo: false
    };
  },
  computed: {
    codigo_max() {
      if (this.tarjeta) {
        let tarjetas = ["AMX", "ABL", "APL"];
        return tarjetas.includes(this.tarjeta.codigo) ? 4 : 3;
      }
      return 3;
    },
    codigo_placeholder() {
      if (this.tarjeta) {
        let tarjetas = ["AMX", "ABL", "APL"];
        return tarjetas.includes(this.tarjeta.codigo) ? "xxxx" : "xxx";
      }
      return "xxx";
    }
  }
};
</script>

<template>
  <div v-if="show" id="form_tarjeta">
    <input type="hidden" name="fpo_categoria" value="1" />

    <input type="hidden" name="fpo_banco_nombre" v-if="banco" v-bind:value="banco.nombre" />
    <input type="hidden" name="fpo_tarjeta_nombre" v-if="tarjeta" v-bind:value="tarjeta.nombre" />

    <div class="page-header">
      <h3>
        <i class="fa fa-credit-card-alt"></i>
        Ingresa los datos de la tarjeta
      </h3>
    </div>

    <div class="panel panel-default panel-form">
      <div class="panel-body">
        <div class="row row-20">
          <div class="col-xs-12 col-sm-6">
            <div class="form-group">
              <label class="control-label">Tarjeta</label>
              <div class="input-group input-group-sm">
                <div class="input-group-addon">
                  <i class="fa fa-credit-card"></i>
                </div>
                <div class="form-control input-sm" v-if="tarjeta" v-html="tarjeta.nombre"></div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6">
            <div class="form-group">
              <label class="control-label">Banco emisor</label>
              <div class="input-group input-group-sm">
                <div class="input-group-addon">
                  <i class="fa fa-university"></i>
                </div>
                <div class="form-control input-sm" v-if="banco" v-html="banco.nombre"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-20">
          <div class="col-xs-12">
            <div class="form-group">
              <label class="control-label">Nombre y apellido</label>
              <input
                name="fpo_nombre"
                type="text"
                class="form-control input-sm"
                autocomplete="off"
                required
              />
              <p class="help-block">Como figura en la tarjeta</p>
            </div>
          </div>
        </div>

        <div class="row row-20">
          <div class="col-xs-12 col-sm-4">
            <div class="form-group">
              <label class="control-label" v-html="'N&uacute;mero de la tarjeta'"></label>
              <the-mask
                type="text"
                mask="####-####-####-####"
                masked
                id="fpo_numero"
                name="fpo_numero"
                required
                maxlength="19"
                placeholder="####-####-####-####"
                autocomplete="off"
                class="form-control input-sm text-monospace"
              ></the-mask>
              <p class="help-block" v-html="'Ingrese los 16 d&iacute;gitos de la tarjeta'"></p>
            </div>
          </div>

          <div class="col-xs-12 col-sm-3">
            <div class="form-group form-group-codigo-seguridad">
              <label class="control-label" v-html="'C&oacute;digo de seguridad'"></label>
              <div class="input-group input-group-sm">
                <the-mask
                  type="text"
                  masked
                  :mask=" codigo_max == 4 ? '####' : '###' "
                  required
                  autocomplete="off"
                  id="fpo_codigo"
                  name="fpo_codigo"
                  class="form-control text-monospace"
                  :maxlength="codigo_max"
                  :placeholder="codigo_placeholder"
                ></the-mask>

                <div class="input-group-btn">
                  <btn
                    type="info"
                    @click="openHelpCodigo = true"
                    :title="'Donde obtengo este c&oacute;digo?'"
                  >
                    <i class="fa fa-question"></i>
                  </btn>
                </div>
              </div>
              <p class="help-block" v-html="'Detr&aacute;s de la tarjeta'"></p>
            </div>
          </div>

          <div class="col-xs-12 col-sm-2">
            <div class="form-group">
              <label class="control-label">Vencimiento</label>
              <the-mask
                type="text"
                masked
                mask="##/##"
                id="fpo_vencimiento"
                name="fpo_vencimiento"
                class="form-control input-sm text-monospace"
                required
                maxlength="5"
                placeholder="MM/YY"
                autocomplete="off"
              ></the-mask>
              <p class="help-block" v-html="'Mes y A&ntilde;o'"></p>
            </div>
          </div>

          <div class="col-xs-12 col-sm-3">
            <div class="form-group">
              <label class="control-label">Es Tarjeta de terceros?</label>
              <select
                name="fpo_tarjeta_terceros"
                id="fpo_tarjeta_terceros"
                class="form-control input-sm"
                autocomplete="off"
                required
              >
                <option value>Seleccionar</option>
                <option value="1">SI</option>
                <option value="2">NO</option>
              </select>
              <p class="help-block">No eres el/la titular</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-header">
      <h3>
        <i class="fa fa-id-card-o"></i>
        Completa los datos personales del titular
      </h3>
    </div>

    <div class="panel panel-default panel-form">
      <div class="panel-body">
        <div class="row row-20">
          <div class="col-xs-12 col-sm-4">
            <div class="form-group">
              <label class="control-label">DNI</label>
              <the-mask
                type="text"
                mask="########"
                masked
                id="fpo_dni"
                name="fpo_dni"
                maxlength="8"
                placeholder="xxxxxxxx"
                autocomplete="off"
                required
                class="form-control input-sm text-monospace imask_dni"
              ></the-mask>
            </div>
          </div>

          <div class="col-xs-12 col-sm-4">
            <div class="form-group">
              <label class="control-label">Sexo</label>
              <select name="fpo_sexo" id="fpo_sexo" class="form-control input-sm">
                <option value>Seleccionar</option>
                <option value="Femenino">Femenino</option>
                <option value="Masculino">Masculino</option>
              </select>
            </div>
          </div>

          <div class="col-xs-12 col-sm-4">
            <div class="form-group">
              <label class="control-label">Fecha de nacimiento</label>
              <the-mask
                type="text"
                mask="##-##-####"
                masked
                id="fpo_nacimiento"
                name="fpo_nacimiento"
                placeholder="dd-mm-yyyy"
                class="form-control input-sm text-monospace"
                maxlength="10"
                required
                autocomplete="off"
              ></the-mask>
            </div>
          </div>
        </div>

        <div class="row row-20">
          <div class="col-xs-12 col-sm-12">
            <div class="form-group">
              <label class="control-label">Domicilio</label>
              <input
                type="text"
                name="fpo_domicilio"
                id="fpo_domicilio"
                class="form-control input-sm"
                autocomplete="off"
                required
              />
              <p
                class="help-block"
                v-html="'Direcci&oacute;n de entrega del resumen de la tarjeta'"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal v-model="openHelpCodigo" :footer="false">
      <template slot="title">
        <div
          v-html="'&iquest;D&oacute;nde est&aacute; el c&oacute;digo de seguridad de mi tarjeta de cr&eacute;dito?'"
        ></div>
      </template>

      <div class="row row-20">
        <div class="col-xs-12 col-sm-7">
          <h4 style="margin-top: 0;">MasterCard y Visa</h4>
          <p v-html="'Voltea tu tarjeta y verifica el &aacute;rea de la firma.'"></p>
          <p
            v-html="'Debes ver el n&uacute;mero de la tarjeta o los &uacute;ltimos cuatro d&iacute;gitos de la tarjeta, seguidos por un c&oacute;digo especial de tres d&iacute;gitos.'"
          ></p>
          <p
            v-html="'Ese c&oacute;digo de tres d&iacute;gitos es el c&oacute;digo de seguridad de la tarjeta.'"
          ></p>
        </div>
        <div class="col-xs-12 col-sm-5">
          <img
            style="margin-top: 25px;"
            :src="url + '/mkte_formas_pago/images/codigo_seguridad/tarjetas_codigo_seguridad1.png'"
            class="img-responsive"
          />
        </div>
      </div>

      <hr />
      <div class="row row-20">
        <div class="col-xs-12 col-sm-7">
          <h4 style="margin-top: 0;">American Express</h4>
          <p
            v-html="'Busca un c&oacute;digo de cuatro d&iacute;gitos impreso en la parte del frente de tu tarjeta, justo encima y a la derecha del n&uacute;mero de la tarjeta.'"
          ></p>
          <p
            v-html="'Ese c&oacute;digo de cuatro d&iacute;gitos es el c&oacute;digo de seguridad de la tarjeta.'"
          ></p>
        </div>
        <div class="col-xs-12 col-sm-5">
          <img
            style="margin-top: 25px;"
            :src="url + '/mkte_formas_pago/images/codigo_seguridad/tarjetas_codigo_seguridad2.png'"
            class="img-responsive"
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<style lang="scss" scoped>
#form_tarjeta {
  > .panel {
    > .panel-body {
      .form-group {
        &.form-group-codigo-seguridad {
          > .input-group {
            > .form-control {
              height: 30px;
              border-left: 1px solid #ccc;
            }
            > .input-group-btn {
              .btn {
                border: 0;
                border-radius: 0;
              }
            }
          }
        }
      }
    }
  }
}

.text-monospace {
  font-family: monospace;
}
</style>