var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
let PoliticasPanelBtn = class PoliticasPanelBtn extends Vue {
    constructor() {
        super(...arguments);
        this.mostrar = this.value;
    }
    onValueChange() {
        this.mostrar = this.value;
    }
    toggle() {
        this.mostrar = !this.mostrar;
        this.$emit('input', this.mostrar);
    }
};
__decorate([
    Prop({ type: Boolean, required: true })
], PoliticasPanelBtn.prototype, "value", void 0);
__decorate([
    Watch("value")
], PoliticasPanelBtn.prototype, "onValueChange", null);
PoliticasPanelBtn = __decorate([
    Component({})
], PoliticasPanelBtn);
export default PoliticasPanelBtn;
