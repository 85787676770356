var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Resultado from "./Resultado/Resultado.vue";
import SinResultados from "./Resultado/SinResultados.vue";
import PlaceholderResultado from "./Placeholder/Resultado.vue";
import InfiniteLoading from 'vue-infinite-loading';
import { Getter, Action } from "vuex-class";
import { Vue, Component } from "vue-property-decorator";
let Resultados = class Resultados extends Vue {
    async infiniteHandler($state) {
        if (this.isPaginadoComplete) {
            $state.complete();
        }
        else {
            await this.paginar();
            $state.loaded();
        }
    }
};
__decorate([
    Action("paginar")
], Resultados.prototype, "paginar", void 0);
__decorate([
    Getter("isLoading")
], Resultados.prototype, "isLoading", void 0);
__decorate([
    Getter("isPaginado")
], Resultados.prototype, "isPaginado", void 0);
__decorate([
    Getter("isPaginadoComplete")
], Resultados.prototype, "isPaginadoComplete", void 0);
__decorate([
    Getter("getPaginado")
], Resultados.prototype, "getPaginado", void 0);
__decorate([
    Getter("isResultados")
], Resultados.prototype, "isResultados", void 0);
__decorate([
    Getter("getResultados")
], Resultados.prototype, "getResultados", void 0);
Resultados = __decorate([
    Component({
        components: {
            Resultado,
            SinResultados,
            InfiniteLoading,
            PlaceholderResultado,
        }
    })
], Resultados);
export default Resultados;
