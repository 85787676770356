var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _ from "lodash";
import { IntegerPlusminus } from "vue-integer-plusminus";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
const pax = { adultos: 1, menores: 0, edades: [] };
let Ocupacion = class Ocupacion extends Vue {
    constructor() {
        super(...arguments);
        this.show = false;
        this.adultos = _.hasIn(this.value, "adultos")
            ? Number(this.value.adultos)
            : 2;
        this.menores = _.hasIn(this.value, "menores")
            ? Number(this.value.menores)
            : 0;
        this.edades = _.hasIn(this.value, "edades")
            ? this.value.edades.map((edad) => Number(edad))
            : [];
    }
    onAdultosChanged() {
        this.$emit("input", {
            adultos: this.adultos,
            menores: this.menores,
            edades: this.edades,
        });
    }
    onMenoresChanged() {
        if (this.menores > 0) {
            for (let i = 0; i < this.maximoMenores - 1; i++) {
                if (i < this.menores) {
                    if (this.edades[i] === undefined) {
                        this.edades[i] = this.minimoEdadMenor;
                    }
                }
            }
        }
        this.edades = _.remove(this.edades, (value, key) => {
            return key < this.menores;
        });
        this.$emit("input", {
            adultos: this.adultos,
            menores: this.menores,
            edades: this.edades,
        });
    }
    onEdadesChanged(val, oldVal) {
        this.$emit("input", {
            adultos: this.adultos,
            menores: this.menores,
            edades: this.edades,
        });
    }
    get total() {
        return this.adultos + this.menores;
    }
    get badgeClass() {
        return "badge-" + _.toString(this.total).length;
    }
    get placeholder() {
        let string = [];
        string.push(this.adultos);
        string.push(this.adultos === 1 ? "Adulto" : "Adultos");
        if (this.menores > 0) {
            string.push("+");
            string.push(this.menores);
            string.push(this.menores === 1 ? "Menor" : "Menores");
        }
        return _.join(string, " ");
    }
    get edadesRangos() {
        return _.range(this.minimoEdadMenor, this.maximoEdadMenor + 1);
    }
};
__decorate([
    Prop({ type: Object, default: () => pax })
], Ocupacion.prototype, "value", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 10 })
], Ocupacion.prototype, "maximoAdultos", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 4 })
], Ocupacion.prototype, "maximoMenores", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 0 })
], Ocupacion.prototype, "minimoEdadMenor", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 17 })
], Ocupacion.prototype, "maximoEdadMenor", void 0);
__decorate([
    Watch("adultos")
], Ocupacion.prototype, "onAdultosChanged", null);
__decorate([
    Watch("menores")
], Ocupacion.prototype, "onMenoresChanged", null);
__decorate([
    Watch("edades")
], Ocupacion.prototype, "onEdadesChanged", null);
Ocupacion = __decorate([
    Component({
        components: { IntegerPlusminus },
    })
], Ocupacion);
export default Ocupacion;
