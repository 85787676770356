var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _ from "lodash";
import Filtro from "./Filtro.vue";
import { Getter, Mutation, Action } from "vuex-class";
import { Vue, Component, Watch } from "vue-property-decorator";
let Duraciones = class Duraciones extends Vue {
    constructor() {
        super(...arguments);
        this.filtro = [];
    }
    get duraciones() {
        let filtros = this.getFiltros;
        if (_.hasIn(filtros, "duraciones")) {
            return this.getFiltros.duraciones;
        }
        return [];
    }
    onFiltroChange(payload) {
        this.setParamDuraciones(payload);
        this.filtrar();
    }
};
__decorate([
    Getter("getFiltros")
], Duraciones.prototype, "getFiltros", void 0);
__decorate([
    Action("filtrar")
], Duraciones.prototype, "filtrar", void 0);
__decorate([
    Mutation("setParamDuraciones")
], Duraciones.prototype, "setParamDuraciones", void 0);
__decorate([
    Watch("filtro")
], Duraciones.prototype, "onFiltroChange", null);
Duraciones = __decorate([
    Component({ components: { Filtro } })
], Duraciones);
export default Duraciones;
