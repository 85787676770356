var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Action, Getter, Mutation } from "vuex-class";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
let PrecioSlider = class PrecioSlider extends Vue {
    constructor() {
        super(...arguments);
        this.filtro = [this.min, this.max];
    }
    formatear(valor) {
        return valor.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
    }
    get placeholder() {
        let min = this.filtro[0].toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
        let max = this.filtro[1].toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
        return `${min} - ${max}`;
    }
    onFiltroChanged() {
        this.setParamPrecio(this.filtro);
        this.filtrar();
    }
};
__decorate([
    Prop({ type: Number, required: true })
], PrecioSlider.prototype, "min", void 0);
__decorate([
    Prop({ type: Number, required: true })
], PrecioSlider.prototype, "max", void 0);
__decorate([
    Action("filtrar")
], PrecioSlider.prototype, "filtrar", void 0);
__decorate([
    Getter("isLoading")
], PrecioSlider.prototype, "isLoading", void 0);
__decorate([
    Mutation("setParamPrecio")
], PrecioSlider.prototype, "setParamPrecio", void 0);
__decorate([
    Watch("filtro")
], PrecioSlider.prototype, "onFiltroChanged", null);
PrecioSlider = __decorate([
    Component({ components: { VueSlider } })
], PrecioSlider);
export default PrecioSlider;
