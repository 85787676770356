var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _ from "lodash";
import Desglose from "../plugins/DesglosePopover.vue";
import { Getter } from "vuex-class";
import { Vue, Component, Prop } from "vue-property-decorator";
let Resultado = class Resultado extends Vue {
    get producto() {
        const producto = this.resultado;
        producto.tarifa = this.getTarifa(producto);
        producto.isTarifa = (producto.tarifa &&
            _.hasIn(producto.tarifa, "rules_tarifa") &&
            producto.tarifa.rules_tarifa) ? true : false;
        return producto;
    }
    getTarifa(producto) {
        const ticket = _.head(producto.tickets);
        if (ticket) {
            const tarifa = _.head(ticket.tarifas);
            if (tarifa) {
                return tarifa;
            }
        }
        return false;
    }
};
__decorate([
    Prop({ type: Object, required: true })
], Resultado.prototype, "resultado", void 0);
__decorate([
    Getter("getConfig")
], Resultado.prototype, "getConfig", void 0);
Resultado = __decorate([
    Component({ components: { Desglose } })
], Resultado);
export default Resultado;
