var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Getter, Mutation, Action } from "vuex-class";
import { Vue, Component, Watch } from "vue-property-decorator";
let Barra = class Barra extends Vue {
    constructor() {
        super(...arguments);
        this.orden = "DESTACADO_DES";
    }
    onOrdenChange(payload) {
        this.setOrden(payload);
        this.filtrar();
    }
};
__decorate([
    Action("filtrar")
], Barra.prototype, "filtrar", void 0);
__decorate([
    Mutation("setOrden")
], Barra.prototype, "setOrden", void 0);
__decorate([
    Getter("getOrden")
], Barra.prototype, "getOrden", void 0);
__decorate([
    Getter("isLoading")
], Barra.prototype, "isLoading", void 0);
__decorate([
    Getter("isPaginado")
], Barra.prototype, "isPaginado", void 0);
__decorate([
    Getter("getPaginado")
], Barra.prototype, "getPaginado", void 0);
__decorate([
    Getter("isResultados")
], Barra.prototype, "isResultados", void 0);
__decorate([
    Watch("orden")
], Barra.prototype, "onOrdenChange", null);
Barra = __decorate([
    Component({ components: {} })
], Barra);
export default Barra;
