var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Action, Getter } from "vuex-class";
import { Vue, Component, Prop } from "vue-property-decorator";
let BtnCotizar = class BtnCotizar extends Vue {
    constructor() {
        super(...arguments);
        this.step = 5;
        this.timer = null;
        this.tarifa = this.value;
        this.loading = false;
        this.progress = 0;
    }
    cotizar() {
        this.loading = true;
        this.timer = setInterval(() => {
            if (this.progress < 100) {
                this.progress = this.progress + this.step;
            }
        }, 500);
        this.politicas(this.value.id)
            .then((response) => {
            this.tarifa.cancelacion_ok = response.ok;
            this.tarifa.reembolsable = response.reembolsable;
            this.tarifa.reserva_requerir = response.requerir;
            this.tarifa.cancelacion_fecha = response.fecha;
            this.tarifa.cancelacion_monto = response.monto;
            this.tarifa.cancelacion_moneda = response.moneda;
            this.tarifa.cancelacion_titulo = response.titulo;
            this.tarifa.cancelacion_politicas = response.politicas;
            this.$emit("input", this.tarifa);
        })
            .catch((error) => {
            console.log(error);
        })
            .finally(() => {
            this.loading = false;
        });
    }
    get reservar() {
        switch (this.getConfig.precio_modo) {
            case 0:
                return true;
                break;
            case 1:
                return false;
                break;
            case 2:
                if (this.tarifa.reembolsable !== null) {
                    if (this.tarifa.reembolsable) {
                        return true;
                    }
                    else {
                        if (this.getConfig.usuario_reembolsable) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                }
                return null;
                break;
            case 3:
                if (this.getConfig.usuario_login) {
                    return true;
                }
                else {
                    return false;
                }
                break;
            case 4:
                if (this.getConfig.usuario_login) {
                    return false;
                }
                else {
                    return true;
                }
                break;
        }
    }
    get consultar() {
        switch (this.getConfig.precio_modo) {
            case 0:
                return false;
                break;
            case 1:
                return true;
                break;
            case 2:
                if (this.tarifa.reembolsable !== null) {
                    if (this.tarifa.reembolsable) {
                        return false;
                    }
                    else {
                        if (this.getConfig.usuario_reembolsable) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                }
                return null;
                break;
            case 3:
                if (this.getConfig.usuario_login) {
                    return false;
                }
                else {
                    return true;
                }
                break;
            case 4:
                if (this.getConfig.usuario_login) {
                    return true;
                }
                else {
                    return false;
                }
                break;
        }
    }
    get urlReservar() {
        return this.getURL(`/services/actividades/reservar/${this.tarifa.id}`);
    }
    get urlConsultar() {
        return this.getURL(`/services/actividades/consultar/${this.tarifa.id}`);
    }
    get isCotizar() {
        return this.tarifa.reembolsable === null;
    }
};
__decorate([
    Prop({ type: Object, required: true })
], BtnCotizar.prototype, "value", void 0);
__decorate([
    Getter("getURL")
], BtnCotizar.prototype, "getURL", void 0);
__decorate([
    Getter("getConfig")
], BtnCotizar.prototype, "getConfig", void 0);
__decorate([
    Action("politicas")
], BtnCotizar.prototype, "politicas", void 0);
BtnCotizar = __decorate([
    Component({})
], BtnCotizar);
export default BtnCotizar;
