import Vue from 'vue';
import store from './store';
import components from './components';
Vue.config.productionTip = false;
import * as uiv from 'uiv';
Vue.use(uiv);
import VueSilentbox from 'vue-silentbox';
Vue.use(VueSilentbox);
import vSelect from 'vue-select';
Vue.component('v-select', vSelect);
new Vue({ el: '#app_act', components, store });
