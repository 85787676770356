import _ from 'lodash';
import axios from 'axios';
const state = {
    formas: false,
    filtros: {},
    config: {
        precio_modo: 1,
        precio_leyenda: 'Desde',
        usuario_login: false,
        usuario_reembolsable: false
    },
    paginado: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0,
    },
    loading: true,
    busquedaId: '',
    comparando: [],
    comparador: false,
    resultados: [],
    busquedaIntentos: 0,
    estado: {
        estado: 0,
        created_at: '',
        conectores: []
    },
    orden: 'DESTACADO_DES',
    params: {
        nombre: '',
        precio: [0, 0],
        conectores: [],
        duraciones: [],
        deshabilitados: false
    },
};
const mutations = {
    setConfig(state, payload) {
        state.config = payload;
    },
    setFormas(state, payload) {
        state.formas = payload;
    },
    setEstado(state, payload) {
        state.estado = payload;
    },
    setFiltros(state, payload) {
        state.filtros = payload;
    },
    setPaginado(state, payload) {
        state.paginado = payload;
    },
    setResultados(state, payload) {
        state.resultados = payload;
    },
    addResultados(state, payload) {
        state.resultados = _.concat(state.resultados, payload);
    },
    setBusquedaId(state, payload) {
        state.busquedaId = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    addBusquedaIntentos(state) {
        state.busquedaIntentos = state.busquedaIntentos + 1;
    },
    comparar(state, resultado) {
        if (_.findIndex(state.comparando, ['id', resultado.id]) > -1) {
            state.comparando = _.reject(state.comparando, ['id', resultado.id]);
            if (state.comparando.length === 0) {
                state.comparador = false;
            }
        }
        else {
            state.comparando.push(resultado);
        }
    },
    toggleComparador(state) {
        state.comparador = !state.comparador;
    },
    resetParams(state) {
        state.params = {
            nombre: '',
            precio: [0, 0],
            conectores: [],
            duraciones: [],
            deshabilitados: false
        };
    },
    setOrden(state, payload) {
        state.orden = payload;
    },
    setParamNombre(state, payload) {
        state.params.nombre = payload;
    },
    setParamPrecio(state, payload) {
        state.params.precio = payload;
    },
    setParamConectores(state, payload) {
        state.params.conectores = payload;
    },
    setParamDuraciones(state, payload) {
        state.params.duraciones = payload;
    },
    setParamDeshabilitados(state, payload) {
        state.params.deshabilitados = payload;
    },
};
const getters = {
    getConfig(state) {
        return state.config;
    },
    getEstado(state) {
        return state.estado;
    },
    isEstado() {
        return state.estado.estado !== 100;
    },
    isPaginado(state) {
        return state.paginado.last_page > 1;
    },
    isPaginadoComplete(state) {
        return state.paginado.last_page > 1 && state.paginado.current_page >= state.paginado.last_page;
    },
    getPaginado(state) {
        return state.paginado;
    },
    getResultados(state) {
        return state.resultados;
    },
    isResultados() {
        return state.resultados.length > 0;
    },
    getOrden(state) {
        return state.orden;
    },
    isLoading(state) {
        return state.loading;
    },
    getFiltros(state) {
        return state.filtros;
    },
    formatearMonto(state) {
        return (monto) => {
            return monto;
        };
    },
    isComparando(state) {
        return state.comparando.length > 0;
    },
    getComparando(state) {
        return state.comparando;
    },
    isComparandoMax(state) {
        return state.comparando.length === 4;
    },
    isComparador(state) {
        return state.comparador === true;
    },
};
const actions = {
    async buscar(context) {
        try {
            const estado = await axios.get(context.getters.getURL(`/services/actividades/busqueda_json/${context.state.busquedaId}`));
            context.commit('setEstado', estado.data);
            const resultados = await axios.get(context.getters.getURL(`/services/actividades/resultados_json/${context.state.busquedaId}`));
            context.commit('setPaginado', resultados.data.meta);
            context.commit('setResultados', resultados.data.data);
            if (estado.data.estado < 100 && context.state.busquedaIntentos < (60 * 5)) {
                context.commit('addBusquedaIntentos');
                setTimeout(async () => {
                    await context.dispatch('buscar');
                }, 1000);
            }
            else {
                const filtros = await axios.get(context.getters.getURL(`/services/actividades/filtros_json/${context.state.busquedaId}`));
                context.commit('setFiltros', filtros.data);
                context.commit('setLoading', false);
            }
        }
        catch (error) {
            console.log(error);
        }
    },
    async filtrar(context) {
        context.commit('setLoading', true);
        context.commit('setResultados', {});
        const params = {
            page: null,
            orden: context.state.orden,
            nombre: context.state.params.nombre,
            precio: null,
            conectores: context.state.params.conectores,
            duraciones: context.state.params.duraciones,
            deshabilitados: context.state.params.deshabilitados ? 1 : null
        };
        if (context.state.params.precio[0] || context.state.params.precio[1]) {
            params.precio = context.state.params.precio;
        }
        const resultados = await axios.get(context.getters.getURL(`/services/actividades/resultados_json/${context.state.busquedaId}`), { params });
        context.commit('setLoading', false);
        context.commit('setPaginado', resultados.data.meta);
        context.commit('setResultados', resultados.data.data);
    },
    async paginar(context) {
        const params = {
            page: context.state.paginado.current_page + 1,
            orden: context.state.orden,
            nombre: context.state.params.nombre,
            precio: null,
            conectores: context.state.params.conectores,
            duraciones: context.state.params.duraciones,
            deshabilitados: context.state.params.deshabilitados ? 1 : null
        };
        if (context.state.params.precio[0] || context.state.params.precio[1]) {
            params.precio = context.state.params.precio;
        }
        const resultados = await axios.get(context.getters.getURL(`/services/actividades/resultados_json/${context.state.busquedaId}`), { params });
        context.commit('setPaginado', resultados.data.meta);
        context.commit('addResultados', resultados.data.data);
    },
    async politicas(context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get(context.getters.getURL(`/services/actividades/politicas/${payload}`))
                .then(response => {
                resolve(response.data);
            })
                .catch(error => {
                console.log(error);
                reject(error);
            });
        });
    }
};
const resultados = {
    state,
    actions,
    getters,
    mutations
};
export default resultados;
