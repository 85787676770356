var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Getter } from "vuex-class";
import { Vue, Component } from "vue-property-decorator";
let Preloader = class Preloader extends Vue {
    get style() {
        const url = this.getURL("/mkte_services/images/ACT/preloader.jpg");
        return { backgroundImage: `url('${url}')` };
    }
};
__decorate([
    Getter("getURL")
], Preloader.prototype, "getURL", void 0);
__decorate([
    Getter("isEstado")
], Preloader.prototype, "isEstado", void 0);
__decorate([
    Getter("getEstado")
], Preloader.prototype, "getEstado", void 0);
Preloader = __decorate([
    Component({ components: {} })
], Preloader);
export default Preloader;
