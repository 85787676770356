var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from "vue-property-decorator";
let Filtro = class Filtro extends Vue {
    constructor() {
        super(...arguments);
        this.mostrar = true;
    }
    get iconoClass() {
        return `mdi-${this.icono}`;
    }
};
__decorate([
    Prop({ type: String, required: true })
], Filtro.prototype, "heading", void 0);
__decorate([
    Prop({ type: String, required: false, default: "" })
], Filtro.prototype, "icono", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 0 })
], Filtro.prototype, "total", void 0);
Filtro = __decorate([
    Component({})
], Filtro);
export default Filtro;
