var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import moment from 'moment';
import { TheMask } from 'vue-the-mask';
import { Vue, Component, Prop } from "vue-property-decorator";
let FechaNacimiento = class FechaNacimiento extends Vue {
    constructor() {
        super(...arguments);
        this.show = false;
        this.date = null;
    }
    get limitFrom() {
        if (this.edad) {
            return moment(this.fecha)
                .subtract(this.edad + 1, 'years')
                .add(1, 'days')
                .toDate();
        }
        return null;
    }
    get limitTo() {
        let edad = this.edad > 0 ? this.edad : 18;
        return moment(this.fecha)
            .subtract(edad, 'years')
            .subtract(1, 'days')
            .toDate();
    }
    get diff() {
        if (this.date) {
            return moment(this.fecha).diff(moment(this.date), 'years');
        }
        return false;
    }
    get isValid() {
        if (this.edad > 0) {
            return this.edad === this.diff;
        }
        return this.diff >= 18;
    }
    get localeES() {
        return {
            uiv: {
                datePicker: {
                    clear: 'Limpiar',
                    today: 'Hoy',
                    month: 'Mes',
                    month1: 'Enero',
                    month2: 'Febrero',
                    month3: 'Marzo',
                    month4: 'Abril',
                    month5: 'Mayo',
                    month6: 'Junio',
                    month7: 'Julio',
                    month8: 'Agosto',
                    month9: 'Septiembre',
                    month10: 'Octubre',
                    month11: 'Noviembre',
                    month12: 'Diciembre',
                    year: 'A&ntilde;o',
                    week1: 'LUN',
                    week2: 'MAR',
                    week3: 'MIE',
                    week4: 'JUE',
                    week5: 'VIE',
                    week6: 'SAB',
                    week7: 'DOM'
                },
                timePicker: { am: 'AM', pm: 'PM' },
                modal: { cancel: 'Cancelar', ok: 'OK' }
            }
        };
    }
    get dateValue() {
        return moment(this.date).format('DD/MM/YYYY');
    }
    get placeholder() {
        if (this.date) {
            return moment(this.date).format('DD/MM/YYYY');
        }
        return 'DD/MM/YYYY';
    }
    toggle() {
        this.show = !this.show;
    }
};
__decorate([
    Prop({ type: String, required: true })
], FechaNacimiento.prototype, "name", void 0);
__decorate([
    Prop({ type: Number, required: true })
], FechaNacimiento.prototype, "pax", void 0);
__decorate([
    Prop({ type: String, required: true })
], FechaNacimiento.prototype, "fecha", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 0 })
], FechaNacimiento.prototype, "edad", void 0);
FechaNacimiento = __decorate([
    Component({ components: { TheMask } })
], FechaNacimiento);
export default FechaNacimiento;
