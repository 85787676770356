var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Filtro from "./Filtro.vue";
import { Mutation, Action } from "vuex-class";
import { Vue, Component, Watch } from "vue-property-decorator";
let Conectores = class Conectores extends Vue {
    constructor() {
        super(...arguments);
        this.deshabilitados = false;
    }
    onDeshabilitadosChange(payload) {
        this.resetParams();
        this.setParamDeshabilitados(payload);
        this.filtrar();
    }
};
__decorate([
    Action("filtrar")
], Conectores.prototype, "filtrar", void 0);
__decorate([
    Mutation("resetParams")
], Conectores.prototype, "resetParams", void 0);
__decorate([
    Mutation("setParamDeshabilitados")
], Conectores.prototype, "setParamDeshabilitados", void 0);
__decorate([
    Watch("deshabilitados")
], Conectores.prototype, "onDeshabilitadosChange", null);
Conectores = __decorate([
    Component({ components: { Filtro } })
], Conectores);
export default Conectores;
