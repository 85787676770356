var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _ from 'lodash';
import axios from 'axios';
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
let Sucursal = class Sucursal extends Vue {
    constructor() {
        super(...arguments);
        this.sucursal = 0;
        this.vendedor = 0;
        this.sucursales = [];
    }
    created() {
        axios.get(this.url + '/mkte_sucursales/sucursales.json.php', {
            params: { modulo: 'HOT' }
        }).then(response => {
            this.sucursales = response.data.sucursales;
        }).catch(error => {
            console.log(error);
        });
    }
    onSucursalChanged(value) {
        if (value === 0) {
            this.vendedor = 0;
        }
    }
    get listo() {
        return this.sucursales.length > 0;
    }
    get cargando() {
        return !(this.sucursales.length > 0);
    }
    get vendedores() {
        let vendedores = [];
        if (!this.cargando && this.sucursal > 0) {
            let sucursal = _.find(this.sucursales, ['id', this.sucursal]);
            if ('vendedores' in sucursal) {
                vendedores = sucursal.vendedores;
            }
        }
        return vendedores;
    }
};
__decorate([
    Prop({ type: String, required: true })
], Sucursal.prototype, "url", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 0 })
], Sucursal.prototype, "post_sucursal", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 0 })
], Sucursal.prototype, "post_vendedor", void 0);
__decorate([
    Watch('sucursal')
], Sucursal.prototype, "onSucursalChanged", null);
Sucursal = __decorate([
    Component({})
], Sucursal);
export default Sucursal;
