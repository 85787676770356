import { render, staticRenderFns } from "./FormularioTarjeta.vue?vue&type=template&id=48b9d5cf&scoped=true&"
import script from "./FormularioTarjeta.vue?vue&type=script&lang=js&"
export * from "./FormularioTarjeta.vue?vue&type=script&lang=js&"
import style0 from "./FormularioTarjeta.vue?vue&type=style&index=0&id=48b9d5cf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../mkte_services/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48b9d5cf",
  null
  
)

export default component.exports