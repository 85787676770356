var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _ from "lodash";
import Filtro from "./Filtro.vue";
import { Getter, Mutation, Action } from "vuex-class";
import { Vue, Component, Watch } from "vue-property-decorator";
let Conectores = class Conectores extends Vue {
    constructor() {
        super(...arguments);
        this.filtro = [];
    }
    get conectores() {
        let filtros = this.getFiltros;
        if (_.hasIn(filtros, "conectores")) {
            return this.getFiltros.conectores;
        }
        return [];
    }
    onFiltroChange(payload) {
        this.setParamConectores(payload);
        this.filtrar();
    }
};
__decorate([
    Getter("getURL")
], Conectores.prototype, "getURL", void 0);
__decorate([
    Getter("getFiltros")
], Conectores.prototype, "getFiltros", void 0);
__decorate([
    Action("filtrar")
], Conectores.prototype, "filtrar", void 0);
__decorate([
    Mutation("setParamConectores")
], Conectores.prototype, "setParamConectores", void 0);
__decorate([
    Watch("filtro")
], Conectores.prototype, "onFiltroChange", null);
Conectores = __decorate([
    Component({ components: { Filtro } })
], Conectores);
export default Conectores;
