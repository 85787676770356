<script>
import Formulario from "./Formulario.vue";
import FormularioTarjeta from "./FormularioTarjeta.vue";
import FormularioTodoPago from "./FormularioTodoPago.vue";

export default {
  components: { Formulario, FormularioTarjeta, FormularioTodoPago },
  props: {
    url: { type: String, required: true },
    urlData: { type: String, required: true }
  },
  data() {
    return {
      forma: false,
      master: false,
      preloader: true
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get(this.urlData, {
          timeout: 60000
        })
        .then(response => {
          this.master = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.preloader = false;
        });
    },
    seleccionar(forma) {
      this.forma = forma;
      this.$emit("seleccionar", forma);
    },
    clear() {
      this.forma = false;
      this.$children[0].clear();
      this.$emit("seleccionar", false);
    }
  },
  computed: {
    isTarjeta() {
      if (this.forma) {
        return this.forma.categoria_id === 1 ? true : false;
      }
      return false;
    },
    isTodoPago() {
      if (this.forma) {
        return this.forma.categoria_id === 5 &&
          this.forma.plataforma === "todopago"
          ? true
          : false;
      }
      return false;
    },
    banco() {
      if (this.isTarjeta) {
        if (this.forma.banco) {
          return this.forma.banco;
        }
        return this.forma.banco;
      }
      return false;
    },
    tarjeta() {
      if (this.isTarjeta) {
        if (this.forma.tarjeta) {
          return this.forma.tarjeta;
        }
      }
      return false;
    }
  }
};
</script>

<template>
  <div>
    <formulario :master="master" @seleccionar="seleccionar"></formulario>

    <formulario-tarjeta :url="url" :show="isTarjeta" :banco="banco" :tarjeta="tarjeta"></formulario-tarjeta>

    <formulario-todo-pago :url="url" :show="isTodoPago"></formulario-todo-pago>
  </div>
</template>

<style lang="scss" scoped>
</style>