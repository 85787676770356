var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Ficha from "./Ficha.vue";
import Formulario from "./Formulario.vue";
import Liquidacion from "./Liquidacion.vue";
import { Vue, Component, Prop } from "vue-property-decorator";
let ConsultarFormulario = class ConsultarFormulario extends Vue {
    get formAction() {
        return `${this.url}/services/actividades/consultar/${this.tarifa.id}`;
    }
};
__decorate([
    Prop({ type: String, required: true })
], ConsultarFormulario.prototype, "url", void 0);
__decorate([
    Prop({ type: Object, required: true })
], ConsultarFormulario.prototype, "datos", void 0);
__decorate([
    Prop({ type: Object, required: true })
], ConsultarFormulario.prototype, "tarifa", void 0);
ConsultarFormulario = __decorate([
    Component({ components: { Ficha, Formulario, Liquidacion } })
], ConsultarFormulario);
export default ConsultarFormulario;
