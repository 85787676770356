var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _ from "lodash";
import { Vue, Component, Prop } from "vue-property-decorator";
let RulesDebug = class RulesDebug extends Vue {
    constructor() {
        super(...arguments);
        this.mostrar = false;
        this.block = this.btnBlock;
        this.moneda = this.monedaShow;
    }
    created() {
        if (this.justified || this.toolbar) {
            this.block = false;
        }
    }
    get monedas() {
        return _.map(this.src, (item) => {
            return item.moneda;
        });
    }
    get rule() {
        const rules = _.find(this.src, ["moneda", this.moneda]);
        if (rules) {
            return rules;
        }
        return false;
    }
    format(monto) {
        let formato = monto.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
        formato = _.replace(formato, "$", "");
        return formato;
    }
};
__decorate([
    Prop({ type: Array, required: true })
], RulesDebug.prototype, "src", void 0);
__decorate([
    Prop({ type: String, required: false, default: "sm" })
], RulesDebug.prototype, "btnSize", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], RulesDebug.prototype, "btnBlock", void 0);
__decorate([
    Prop({ type: String, required: false, default: "USD" })
], RulesDebug.prototype, "monedaShow", void 0);
__decorate([
    Prop({ type: Object, required: false, default: () => { } })
], RulesDebug.prototype, "btnStyle", void 0);
__decorate([
    Prop({ type: String, required: false, default: "info" })
], RulesDebug.prototype, "btnType", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], RulesDebug.prototype, "toolbar", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], RulesDebug.prototype, "justified", void 0);
RulesDebug = __decorate([
    Component({ components: {} })
], RulesDebug);
export default RulesDebug;
