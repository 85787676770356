import Vue from 'vue';
import Vuex from "vuex";
import resultados from './Resultados';
Vue.use(Vuex);
const state = {
    url: '',
    dev: false,
    pow: false,
    admin: false
};
const getters = {
    getURL(state) {
        return (uri = false) => {
            return (uri) ? `${state.url}${uri}` : state.url;
        };
    },
    isDev(state) {
        return state.dev;
    },
    isPow(state) {
        return state.pow;
    },
    isAdmin(state) {
        return state.admin;
    }
};
const mutations = {
    setURL(state, payload) {
        state.url = payload;
    },
    setDev(state, payload) {
        state.dev = payload;
    },
    setPow(state, payload) {
        state.pow = payload;
    },
    setAdmin(state, payload) {
        state.admin = payload;
    }
};
const store = {
    modules: { resultados },
    state,
    getters,
    mutations
};
export default new Vuex.Store(store);
