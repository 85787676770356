var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import axios from "axios";
import moment from "moment";
import Ocupacion from "./Ocupacion.vue";
import Calendario from "./Calendario.vue";
import { Getter, Mutation } from "vuex-class";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
const pax = { adultos: 2, menores: 0, edades: [] };
const params = {
    desde: '',
    hasta: '',
    destino: '',
    pasajeros: pax,
    nacionalidad: 'AR'
};
const destinoEmpty = {
    CiudadId: '0',
    CiudadNombre: '',
    DestinoId: '',
    DestinoGeo: '',
    DestinoTipo: '',
    DestinoIATA: '',
    DestinoHoteles: true,
    DestinoLatitud: '',
    DestinoLongitud: '',
    DestinoNivelZoom: 0,
    DestinoDescripcion: '',
    DestinoRadioBusqueda: 0,
    PaisCodigo: '',
    PaisNombre: '',
    RegionCodigo: '',
    RegionNombre: '',
    label: '',
    icono: ''
};
const nacionalidadEmpty = {
    PaisCodigo2: "",
    PaisCodigo3: "",
    PaisNombre: ""
};
let Buscador = class Buscador extends Vue {
    constructor() {
        super(...arguments);
        this.desde = this.params.desde;
        this.hasta = this.params.hasta;
        this.pasajeros = this.params.pasajeros;
        this.destino = destinoEmpty;
        this.nacionalidad = nacionalidadEmpty;
    }
    created() {
        this.setURL(this.url);
        if (this.params.destino.length) {
            axios.get(this.getURL(`/services/actividades/destino/${escape(this.params.destino)}`)).then((response) => {
                this.destino = response.data;
            });
        }
        if (!this.params.nacionalidad.length) {
            this.params.nacionalidad = this.nacionalidadDefault;
        }
        axios.get(this.getURL(`/services/actividades/nacionalidad/${escape(this.params.nacionalidad)}`)).then((response) => {
            this.nacionalidad = response.data;
        });
    }
    searchDestinos(query, done) {
        axios.get(this.getURL(`/services/actividades/destinos/${escape(query)}`)).then((response) => {
            done(response.data);
        });
    }
    searchNacionalidades(query, done) {
        axios.get(this.getURL(`/services/actividades/nacionalidades/${escape(query)}`)).then((response) => {
            done(response.data);
        });
    }
    onDesdeChange(fecha) {
        let desde = moment(fecha);
        if (this.hasta) {
            let hasta = moment(this.hasta);
            if (desde > hasta) {
                this.hasta = desde.format('YYYY-MM-DD');
            }
        }
        else {
            this.hasta = desde.format('YYYY-MM-DD');
        }
    }
    get dias() {
        if (this.desde && this.hasta) {
            let dias = moment(this.hasta).diff(moment(this.desde), 'days');
            dias++;
            return dias > 1 ? `${dias} D&iacute;as` : `${dias} D&iacute;a`;
        }
        return '';
    }
    get urlAction() {
        return this.getURL('/services/actividades');
    }
    get isValid() {
        if (!this.destino || !this.destino.DestinoId) {
            return false;
        }
        if (!this.desde || !this.hasta) {
            return false;
        }
        if (!this.pasajeros ||
            (this.pasajeros && this.pasajeros.adultos == 0) ||
            (this.pasajeros && this.pasajeros.menores && this.pasajeros.edades && this.pasajeros.edades.length !== this.pasajeros.menores)) {
            return false;
        }
        if (!this.nacionalidad || !this.nacionalidad.PaisCodigo2) {
            return false;
        }
        return true;
    }
};
__decorate([
    Prop(String)
], Buscador.prototype, "url", void 0);
__decorate([
    Prop({ type: Object, default: () => params })
], Buscador.prototype, "params", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 10 })
], Buscador.prototype, "maximoAdultos", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 4 })
], Buscador.prototype, "maximoMenores", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 0 })
], Buscador.prototype, "minimoEdadMenor", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 17 })
], Buscador.prototype, "maximoEdadMenor", void 0);
__decorate([
    Prop({ type: String, required: false, default: "AR" })
], Buscador.prototype, "nacionalidadDefault", void 0);
__decorate([
    Getter('getURL')
], Buscador.prototype, "getURL", void 0);
__decorate([
    Mutation('setURL')
], Buscador.prototype, "setURL", void 0);
__decorate([
    Watch("desde")
], Buscador.prototype, "onDesdeChange", null);
Buscador = __decorate([
    Component({ components: { Calendario, Ocupacion } })
], Buscador);
export default Buscador;
