var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from "vue-property-decorator";
let Acrotar = class Acrotar extends Vue {
    constructor() {
        super(...arguments);
        this.mostrar = false;
    }
    toggle() {
        this.mostrar = !this.mostrar;
    }
    get isLong() {
        return this.src.length >= this.size;
    }
    get isShort() {
        return this.src.length <= this.size;
    }
    get shortText() {
        return this.src.substring(0, this.size) + "... ";
    }
    get longText() {
        return this.src;
    }
};
__decorate([
    Prop({ type: String, required: true })
], Acrotar.prototype, "src", void 0);
__decorate([
    Prop({ type: Number, default: 500 })
], Acrotar.prototype, "size", void 0);
Acrotar = __decorate([
    Component({})
], Acrotar);
export default Acrotar;
