var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _ from "lodash";
import Resultado from "./Resultado.vue";
import EmailModal from "./EmailModal.vue";
import { Getter, Mutation } from "vuex-class";
import { Vue, Component } from "vue-property-decorator";
let Panel = class Panel extends Vue {
    constructor() {
        super(...arguments);
        this.showEmailModal = false;
    }
    remove(producto) {
        this.comparar(producto);
    }
    get total() {
        return this.getComparando.length;
    }
    get col() {
        if (this.total === 3) {
            return 4;
        }
        else if (this.total === 4) {
            return 3;
        }
        return 6;
    }
    get ids() {
        return !this.isComparando ? [] : _.map(this.getComparando, 'id');
    }
    enviarEmail() {
        this.showEmailModal = !this.showEmailModal;
    }
    descargarPDF() {
        window.open(this.getURL(`/services/actividades/comparador_pdf/${_.join(this.ids, '-')}.pdf`), '_blank');
    }
};
__decorate([
    Getter("getURL")
], Panel.prototype, "getURL", void 0);
__decorate([
    Getter("isComparador")
], Panel.prototype, "isComparador", void 0);
__decorate([
    Getter("isComparando")
], Panel.prototype, "isComparando", void 0);
__decorate([
    Getter("getComparando")
], Panel.prototype, "getComparando", void 0);
__decorate([
    Mutation("comparar")
], Panel.prototype, "comparar", void 0);
__decorate([
    Mutation("toggleComparador")
], Panel.prototype, "toggleComparador", void 0);
Panel = __decorate([
    Component({ components: { Resultado, EmailModal } })
], Panel);
export default Panel;
