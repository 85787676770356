var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _ from "lodash";
import Fotos from "./Fotos.vue";
import { Vue, Component, Prop } from "vue-property-decorator";
let Ficha = class Ficha extends Vue {
    get tarifa() {
        let tarifa = _.clone(this.ficha);
        delete tarifa.ticket;
        return tarifa;
    }
    get ticket() {
        let ticket = _.clone(this.ficha.ticket);
        delete ticket.actividad;
        return ticket;
    }
    get actividad() {
        let actividad = _.clone(this.ficha.ticket.actividad);
        delete actividad.conector;
        return actividad;
    }
    get conector() {
        return this.ficha.ticket.actividad.conector;
    }
};
__decorate([
    Prop({ type: Object, required: true })
], Ficha.prototype, "ficha", void 0);
Ficha = __decorate([
    Component({ components: { Fotos } })
], Ficha);
export default Ficha;
