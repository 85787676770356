var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _ from "lodash";
import axios from "axios";
import Desglose from "../plugins/DesglosePopover.vue";
import EmailModal from "../plugins/EmailModal.vue";
import RulesDebug from "../plugins/RulesDebug.vue";
import FormasDePago from "../../../plugins/FormasDePago.vue";
import Fotos from "./Fotos.vue";
import Tarifas from "./Tarifas.vue";
import { Getter, Mutation } from "vuex-class";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Notification } from "uiv";
let Resultado = class Resultado extends Vue {
    constructor() {
        super(...arguments);
        this.email = false;
        this.destacado = false;
        this.habilitar = true;
        this.destacando = false;
        this.deshabilitando = false;
        this.$notify = Notification.notify;
    }
    created() {
        this.destacado = this.actividad.destacado;
        this.habilitar = this.actividad.habilitado;
    }
    get tickets() {
        return this.actividad.tickets;
    }
    get tarifa() {
        const ticket = _.head(this.actividad.tickets);
        if (ticket) {
            const tarifa = _.head(ticket.tarifas);
            if (tarifa) {
                return tarifa;
            }
        }
        return false;
    }
    get isTarifa() {
        return (this.tarifa &&
            _.hasIn(this.tarifa, "rules_tarifa") &&
            this.tarifa.rules_tarifa);
    }
    onActividadChanged() {
        this.destacado = this.actividad.destacado;
        this.habilitar = this.actividad.habilitado;
    }
    async destacar() {
        this.destacando = true;
        const uri = `/services/actividades/destacar/${this.actividad.id}`;
        const url = this.getURL(uri);
        const params = {
            modulo: "ACT",
            codigo: this.actividad.codigo,
            conector: this.actividad.conector.codigo,
            original: this.actividad.nombre,
        };
        try {
            const response = await axios.get(url, { params });
            this.destacado = response.data.destacar;
            if (this.destacado) {
                this.$notify.success({
                    icon: "mdi mdi-star",
                    title: "Se destaco la Actividad correctamente.",
                    content: "Los cambios se reflejaran en la proxima busqueda",
                });
            }
            else {
                this.$notify.success({
                    icon: "mdi mdi-star-outline",
                    title: "Se elimino el destaque",
                    content: "Los cambios se reflejaran en la proxima busqueda",
                });
            }
        }
        catch (error) {
            this.$notify.danger({ title: "Error", content: error });
        }
        this.destacando = false;
    }
    async deshabilitar() {
        this.deshabilitando = true;
        const uri = `/services/actividades/deshabilitar/${this.actividad.id}`;
        const url = this.getURL(uri);
        const params = {
            modulo: "ACT",
            codigo: this.actividad.codigo,
            conector: this.actividad.conector.codigo,
            original: this.actividad.nombre,
        };
        try {
            const response = await axios.get(url, { params });
            this.habilitar = response.data.habilitar;
            if (this.habilitar) {
                this.$notify.success({
                    icon: "mdi mdi-eye",
                    title: "Se habilito la Actividad correctamente.",
                    content: "Los cambios se reflejaran en la proxima busqueda",
                });
            }
            else {
                this.$notify.success({
                    icon: "mdi mdi-eye-off",
                    title: "Se ha deshabilitado",
                    content: "Los cambios se reflejaran en la proxima busqueda",
                });
            }
        }
        catch (error) {
            this.$notify.danger({ title: "Error", content: error });
        }
        this.deshabilitando = false;
    }
    toggleComparar() {
        this.comparar(this.actividad);
    }
    get isComparando() {
        const comparando = this.getComparando;
        if (comparando.length) {
            return _.findIndex(comparando, ["id", this.actividad.id]) > -1;
        }
        return false;
    }
    toggleEmail() {
        this.email = !this.email;
    }
    openPDF() {
        window.open(this.getURL(`/services/actividades/pdf/${this.actividad.id}.pdf`), '_blank');
    }
};
__decorate([
    Prop({ type: Object, required: true })
], Resultado.prototype, "actividad", void 0);
__decorate([
    Getter("getURL")
], Resultado.prototype, "getURL", void 0);
__decorate([
    Getter("isAdmin")
], Resultado.prototype, "isAdmin", void 0);
__decorate([
    Getter("getConfig")
], Resultado.prototype, "getConfig", void 0);
__decorate([
    Getter("getComparando")
], Resultado.prototype, "getComparando", void 0);
__decorate([
    Getter("isComparandoMax")
], Resultado.prototype, "isComparandoMax", void 0);
__decorate([
    Mutation("comparar")
], Resultado.prototype, "comparar", void 0);
__decorate([
    Watch("actividad", { deep: true })
], Resultado.prototype, "onActividadChanged", null);
Resultado = __decorate([
    Component({
        components: {
            Fotos,
            Tarifas,
            Desglose,
            EmailModal,
            RulesDebug,
            FormasDePago,
        },
    })
], Resultado);
export default Resultado;
