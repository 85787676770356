var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { formatMoney } from 'accounting-js';
import { Vue, Component, Prop } from "vue-property-decorator";
let Liquidacion = class Liquidacion extends Vue {
    constructor() {
        super(...arguments);
        this.show = true;
        this.formatoOpts = { symbol: '', decimal: '.', thousand: ',', precision: 0 };
    }
    created() {
        switch (this.formato) {
            case 'US-0':
                this.formatoOpts.decimal = '.';
                this.formatoOpts.thousand = ',';
                this.formatoOpts.precision = 0;
                break;
            case 'US-2':
                this.formatoOpts.decimal = '.';
                this.formatoOpts.thousand = ',';
                this.formatoOpts.precision = 2;
                break;
            case 'LA-0':
                this.formatoOpts.decimal = ',';
                this.formatoOpts.thousand = '.';
                this.formatoOpts.precision = 0;
                break;
            case 'LA-2':
                this.formatoOpts.decimal = ',';
                this.formatoOpts.thousand = '.';
                this.formatoOpts.precision = 2;
                break;
        }
    }
    get moneda() {
        return this.src.moneda;
    }
    get totalNoches() {
        return this.src.desglose.total_noches_format;
    }
    get tarifa() {
        return this.src.desglose.base_fare_format;
    }
    get taxes() {
        return this.src.desglose.taxes_format;
    }
    get rules() {
        return this.src.desglose.rules;
    }
    get subTotal() {
        return this.src.desglose.total_fare_format;
    }
    get total() {
        if (this.forma) {
            let total = Number(this.src.desglose.total_fare);
            total = total + Number(this.forma.monto);
            return this.formatear(total);
        }
        return this.src.desglose.total_fare_format;
    }
    get formaMonto() {
        if (this.forma) {
            return this.formatear(this.forma.monto);
        }
        return false;
    }
    formatear(value) {
        return formatMoney(value, this.formatoOpts);
    }
};
__decorate([
    Prop({ type: Object, required: true })
], Liquidacion.prototype, "src", void 0);
__decorate([
    Prop({ type: String, required: false, default: 'LA-0' })
], Liquidacion.prototype, "formato", void 0);
__decorate([
    Prop({ type: [Object, Boolean], required: false, default: false })
], Liquidacion.prototype, "forma", void 0);
Liquidacion = __decorate([
    Component({})
], Liquidacion);
export default Liquidacion;
