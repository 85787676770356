var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { hasIn, head, findIndex, map, find } from "lodash";
import moment from "moment";
import { Getter } from "vuex-class";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Acortar from "../plugins/Acortar.vue";
import Desglose from "../plugins/DesglosePopover.vue";
import BtnCotizar from "./BtnCotizar.vue";
import RulesDebug from "../plugins/RulesDebug.vue";
import PoliticasPanel from "./PoliticasPanel.vue";
import PoliticasPanelBtn from "./PoliticasPanelBtn.vue";
let TarifaRow = class TarifaRow extends Vue {
    constructor() {
        super(...arguments);
        this.tarifaId = 0;
        this.tarifas = this.ticket.tarifas;
        this.politicasShow = false;
    }
    created() {
        this.tarifas = this.ticket.tarifas;
        const tarifa = head(this.tarifas);
        if (tarifa) {
            this.tarifaId = tarifa.id;
        }
    }
    get fechas() {
        return map(this.tarifas, (tarifa) => {
            return {
                id: tarifa.id,
                fecha: moment(tarifa.fecha).format("DD-MM-YYYY"),
            };
        });
    }
    get tarifaKey() {
        return findIndex(this.tarifas, ["id", this.tarifaId]);
    }
    isTarifa(id) {
        const tarifa = find(this.tarifas, ["id", this.tarifaId]);
        return (tarifa && hasIn(tarifa, "rules_tarifa") && tarifa.rules_tarifa);
    }
    onTarifaIdChnaged() {
        this.politicasShow = false;
    }
    onTticketChnaged() {
        this.tarifas = this.ticket.tarifas;
    }
};
__decorate([
    Prop({ type: Object, required: true })
], TarifaRow.prototype, "ticket", void 0);
__decorate([
    Getter("getURL")
], TarifaRow.prototype, "getURL", void 0);
__decorate([
    Getter("isAdmin")
], TarifaRow.prototype, "isAdmin", void 0);
__decorate([
    Watch('tarifaId')
], TarifaRow.prototype, "onTarifaIdChnaged", null);
__decorate([
    Watch('ticket')
], TarifaRow.prototype, "onTticketChnaged", null);
TarifaRow = __decorate([
    Component({
        components: {
            Acortar,
            Desglose,
            RulesDebug,
            BtnCotizar,
            PoliticasPanel,
            PoliticasPanelBtn
        }
    })
], TarifaRow);
export default TarifaRow;
