var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Barra from "./Barra.vue";
import Buscador from "./Buscador.vue";
import Preloader from "./Preloader.vue";
import Resultados from "./Resultados.vue";
import ComparadorPanel from "./Comparador/Panel.vue";
import ComparadorListado from "./Comparador/Listado.vue";
import GoTopButton from "vue-go-top-button";
import FiltroAdmin from "./Filtros/Admin.vue";
import FiltroTexto from "./Filtros/Texto.vue";
import FiltroPrecio from "./Filtros/Precio.vue";
import FiltroConector from "./Filtros/Conectores.vue";
import FiltroDuraciones from "./Filtros/Duraciones.vue";
import { Mutation, Action, Getter } from "vuex-class";
import { Vue, Component, Prop } from "vue-property-decorator";
let ResultadosBusqueda = class ResultadosBusqueda extends Vue {
    created() {
        this.setURL(this.url);
        this.setDev(this.isDev);
        this.setPow(this.isPow);
        this.setConfig(this.config);
        this.setAdmin(this.isAdmin);
        this.setFormas(this.isFormas);
        this.setBusquedaId(this.busquedaId);
        this.buscar();
    }
    resetParams() {
        this.storeResetParams();
        this.filtrar();
    }
};
__decorate([
    Prop({ type: String, required: true })
], ResultadosBusqueda.prototype, "url", void 0);
__decorate([
    Prop({ type: Object, required: true })
], ResultadosBusqueda.prototype, "config", void 0);
__decorate([
    Prop({ type: Object, required: true })
], ResultadosBusqueda.prototype, "params", void 0);
__decorate([
    Prop({ type: String, required: true })
], ResultadosBusqueda.prototype, "busquedaId", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ResultadosBusqueda.prototype, "isFormas", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ResultadosBusqueda.prototype, "isDev", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ResultadosBusqueda.prototype, "isPow", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ResultadosBusqueda.prototype, "isAdmin", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 10 })
], ResultadosBusqueda.prototype, "maximoAdultos", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 4 })
], ResultadosBusqueda.prototype, "maximoMenores", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 0 })
], ResultadosBusqueda.prototype, "minimoEdadMenor", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 17 })
], ResultadosBusqueda.prototype, "maximoEdadMenor", void 0);
__decorate([
    Prop({ type: String, required: false, default: "AR" })
], ResultadosBusqueda.prototype, "nacionalidadDefault", void 0);
__decorate([
    Getter("isComparador")
], ResultadosBusqueda.prototype, "isComparador", void 0);
__decorate([
    Action("buscar")
], ResultadosBusqueda.prototype, "buscar", void 0);
__decorate([
    Action("filtrar")
], ResultadosBusqueda.prototype, "filtrar", void 0);
__decorate([
    Mutation("setURL")
], ResultadosBusqueda.prototype, "setURL", void 0);
__decorate([
    Mutation("setDev")
], ResultadosBusqueda.prototype, "setDev", void 0);
__decorate([
    Mutation("setPow")
], ResultadosBusqueda.prototype, "setPow", void 0);
__decorate([
    Mutation("setAdmin")
], ResultadosBusqueda.prototype, "setAdmin", void 0);
__decorate([
    Mutation("setConfig")
], ResultadosBusqueda.prototype, "setConfig", void 0);
__decorate([
    Mutation("setFormas")
], ResultadosBusqueda.prototype, "setFormas", void 0);
__decorate([
    Mutation("resetParams")
], ResultadosBusqueda.prototype, "storeResetParams", void 0);
__decorate([
    Mutation("setBusquedaId")
], ResultadosBusqueda.prototype, "setBusquedaId", void 0);
ResultadosBusqueda = __decorate([
    Component({
        components: {
            Barra,
            Buscador,
            Preloader,
            Resultados,
            GoTopButton,
            FiltroAdmin,
            FiltroTexto,
            FiltroPrecio,
            FiltroConector,
            ComparadorPanel,
            FiltroDuraciones,
            ComparadorListado,
        },
    })
], ResultadosBusqueda);
export default ResultadosBusqueda;
