var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Action, Getter } from "vuex-class";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
let PoliticasPanel = class PoliticasPanel extends Vue {
    constructor() {
        super(...arguments);
        this.step = 5;
        this.timer = null;
        this.tarifa = this.value;
        this.mostrar = this.show;
        this.loading = false;
        this.progress = 0;
        this.aclaracion = `Fecha y hora se calcula bas${String.fromCharCode(225)}ndose en la hora local del destino.`;
    }
    onShowChange() {
        if (this.mostrar != this.show) {
            this.toggle();
        }
    }
    onTarifaChange() {
        if (this.value != this.tarifa) {
            this.$emit('input', this.tarifa);
        }
    }
    toggle() {
        this.mostrar = !this.mostrar;
        if (this.tarifa.cancelacion_ok === false) {
            this.cotizar();
        }
    }
    cotizar() {
        this.loading = true;
        this.timer = setInterval(() => {
            if (this.progress < 100) {
                this.progress = this.progress + this.step;
            }
        }, 500);
        this.politicas(this.tarifa.id)
            .then((response) => {
            this.tarifa.cancelacion_ok = response.ok;
            this.tarifa.reembolsable = response.reembolsable;
            this.tarifa.reserva_requerir = response.requerir;
            this.tarifa.cancelacion_fecha = response.fecha;
            this.tarifa.cancelacion_monto = response.monto;
            this.tarifa.cancelacion_moneda = response.moneda;
            this.tarifa.cancelacion_titulo = response.titulo;
            this.tarifa.cancelacion_politicas = response.politicas;
        })
            .catch((error) => {
            console.log(error);
        })
            .finally(() => {
            this.loading = false;
        });
    }
    get titulo() {
        if (this.loading) {
            return 'Procesando...';
        }
        return this.tarifa.cancelacion_titulo;
    }
    get panelClass() {
        if (this.tarifa.cancelacion_ok) {
            if (this.tarifa.reembolsable === true) {
                return 'panel-success';
            }
            else if (this.tarifa.reembolsable === false) {
                return 'panel-danger';
            }
            else if (this.tarifa.reembolsable === null) {
                return 'panel-warning';
            }
        }
        return 'panel-default';
    }
};
__decorate([
    Prop({ type: Object, required: true })
], PoliticasPanel.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], PoliticasPanel.prototype, "show", void 0);
__decorate([
    Getter("getURL")
], PoliticasPanel.prototype, "getURL", void 0);
__decorate([
    Action("politicas")
], PoliticasPanel.prototype, "politicas", void 0);
__decorate([
    Watch("show")
], PoliticasPanel.prototype, "onShowChange", null);
__decorate([
    Watch("tarifa")
], PoliticasPanel.prototype, "onTarifaChange", null);
PoliticasPanel = __decorate([
    Component({})
], PoliticasPanel);
export default PoliticasPanel;
