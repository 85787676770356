var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import moment from "moment";
import countdown from "countdown";
import { Vue, Component, Prop } from "vue-property-decorator";
let Timer = class Timer extends Vue {
    constructor() {
        super(...arguments);
        this.end = null;
        this.timeout = false;
        this.minutos = "--";
        this.segundos = "--";
    }
    created() {
        if (this.src > 0) {
            this.end = moment().add(this.src, "seconds");
            setInterval(() => {
                if (this.timeout === false) {
                    this.countdown();
                }
            }, 1000 * 1);
        }
        else {
            this.redireccionar();
        }
    }
    countdown() {
        if (this.end) {
            let timer = countdown(moment(), this.end.toDate());
            this.minutos = timer.minutes;
            this.segundos = timer.seconds;
            if (timer.value < 0) {
                this.redireccionar();
            }
            return true;
        }
        return false;
    }
    redireccionar() {
        this.$emit("timeout");
        this.timeout = true;
        this.minutos = "--";
        this.segundos = "--";
        if (this.dev) {
            return false;
        }
        return window.location.href = this.url + `/services/actividades/resultados/${this.busquedaId}`;
    }
};
__decorate([
    Prop({ type: String, required: true })
], Timer.prototype, "url", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 0 })
], Timer.prototype, "src", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], Timer.prototype, "dev", void 0);
__decorate([
    Prop({ type: String, required: true })
], Timer.prototype, "busquedaId", void 0);
Timer = __decorate([
    Component({})
], Timer);
export default Timer;
