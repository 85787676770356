var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import vSelect from 'vue-select';
import { Vue, Component, Prop } from "vue-property-decorator";
Vue.component('v-select', vSelect);
const paises = [
    { id: 'AD', nombre: 'Andorra' },
    { id: 'AE', nombre: 'Emiratos Árabes Unidos' },
    { id: 'AF', nombre: 'Afganistán' },
    { id: 'AG', nombre: 'Antigua y Barbuda' },
    { id: 'AI', nombre: 'Anguila' },
    { id: 'AL', nombre: 'Albania' },
    { id: 'AM', nombre: 'Armenia' },
    { id: 'AO', nombre: 'Angola' },
    { id: 'AQ', nombre: 'Antártida' },
    { id: 'AR', nombre: 'Argentina' },
    { id: 'AS', nombre: 'Samoa Americana' },
    { id: 'AT', nombre: 'Austria' },
    { id: 'AU', nombre: 'Australia' },
    { id: 'AW', nombre: 'Aruba' },
    { id: 'AX', nombre: 'Islas de Aland' },
    { id: 'AZ', nombre: 'Azerbaiyán' },
    { id: 'BA', nombre: 'Bosnia y Herzegovina' },
    { id: 'BB', nombre: 'Barbados' },
    { id: 'BD', nombre: 'Bangladesh' },
    { id: 'BE', nombre: 'Bélgica' },
    { id: 'BF', nombre: 'Burkina Faso' },
    { id: 'BG', nombre: 'Bulgaria' },
    { id: 'BH', nombre: 'Bahrein' },
    { id: 'BI', nombre: 'Burundi' },
    { id: 'BJ', nombre: 'Benín' },
    { id: 'BL', nombre: 'San Bartolomé' },
    { id: 'BM', nombre: 'Islas Bermudas' },
    { id: 'BN', nombre: 'Brunéi' },
    { id: 'BO', nombre: 'Bolivia' },
    { id: 'BQ', nombre: 'Bonaire, San Eustaquio y Saba' },
    { id: 'BR', nombre: 'Brasil' },
    { id: 'BS', nombre: 'Bahamas' },
    { id: 'BT', nombre: 'Bhután' },
    { id: 'BV', nombre: 'Isla Bouvet' },
    { id: 'BW', nombre: 'Botsuana' },
    { id: 'BY', nombre: 'Bielorrusia' },
    { id: 'BZ', nombre: 'Belice' },
    { id: 'CA', nombre: 'Canadá' },
    { id: 'CC', nombre: 'Islas Cocos (Keeling)' },
    { id: 'CD', nombre: 'República Democrática del Congo' },
    { id: 'CF', nombre: 'República Centroafricana' },
    { id: 'CG', nombre: 'República del Congo' },
    { id: 'CH', nombre: 'Suiza' },
    { id: 'CI', nombre: 'Costa de Marfil' },
    { id: 'CK', nombre: 'Islas Cook' },
    { id: 'CL', nombre: 'Chile' },
    { id: 'CM', nombre: 'Camerún' },
    { id: 'CN', nombre: 'China' },
    { id: 'CO', nombre: 'Colombia' },
    { id: 'CR', nombre: 'Costa Rica' },
    { id: 'CU', nombre: 'Cuba' },
    { id: 'CV', nombre: 'Cabo Verde' },
    { id: 'CW', nombre: 'Curazao' },
    { id: 'CX', nombre: 'Isla de Navidad' },
    { id: 'CY', nombre: 'Chipre' },
    { id: 'CZ', nombre: 'República Checa' },
    { id: 'DE', nombre: 'Alemania' },
    { id: 'DJ', nombre: 'Yibuti' },
    { id: 'DK', nombre: 'Dinamarca' },
    { id: 'DM', nombre: 'Dominica' },
    { id: 'DO', nombre: 'República Dominicana' },
    { id: 'DZ', nombre: 'Algeria' },
    { id: 'EC', nombre: 'Ecuador' },
    { id: 'EE', nombre: 'Estonia' },
    { id: 'EG', nombre: 'Egipto' },
    { id: 'EH', nombre: 'Sahara Occidental' },
    { id: 'ER', nombre: 'Eritrea' },
    { id: 'ES', nombre: 'España' },
    { id: 'ET', nombre: 'Etiopía' },
    { id: 'FI', nombre: 'Finlandia' },
    { id: 'FJ', nombre: 'Fiyi' },
    { id: 'FK', nombre: 'Islas Malvinas' },
    { id: 'FM', nombre: 'Micronesia' },
    { id: 'FO', nombre: 'Islas Feroe' },
    { id: 'FR', nombre: 'Francia' },
    { id: 'GA', nombre: 'Gabón' },
    { id: 'GB', nombre: 'Reino Unido' },
    { id: 'GD', nombre: 'Granada' },
    { id: 'GE', nombre: 'Georgia' },
    { id: 'GF', nombre: 'Guayana Francesa' },
    { id: 'GG', nombre: 'Guernsey' },
    { id: 'GH', nombre: 'Ghana' },
    { id: 'GI', nombre: 'Gibraltar' },
    { id: 'GL', nombre: 'Groenlandia' },
    { id: 'GM', nombre: 'Gambia' },
    { id: 'GN', nombre: 'Guinea' },
    { id: 'GP', nombre: 'Guadalupe' },
    { id: 'GQ', nombre: 'Guinea Ecuatorial' },
    { id: 'GR', nombre: 'Grecia' },
    { id: 'GS', nombre: 'Islas Georgias del Sur y Sandwich del Sur' },
    { id: 'GT', nombre: 'Guatemala' },
    { id: 'GU', nombre: 'Guam' },
    { id: 'GW', nombre: 'Guinea-Bissau' },
    { id: 'GY', nombre: 'Guyana' },
    { id: 'HK', nombre: 'Hong kong' },
    { id: 'HM', nombre: 'Islas Heard y McDonald' },
    { id: 'HN', nombre: 'Honduras' },
    { id: 'HR', nombre: 'Croacia' },
    { id: 'HT', nombre: 'Haití' },
    { id: 'HU', nombre: 'Hungría' },
    { id: 'ID', nombre: 'Indonesia' },
    { id: 'IE', nombre: 'Irlanda' },
    { id: 'IL', nombre: 'Israel' },
    { id: 'IM', nombre: 'Isla de Man' },
    { id: 'IN', nombre: 'India' },
    { id: 'IO', nombre: 'Territorio Británico del Océano Índico' },
    { id: 'IQ', nombre: 'Irak' },
    { id: 'IR', nombre: 'Irán' },
    { id: 'IS', nombre: 'Islandia' },
    { id: 'IT', nombre: 'Italia' },
    { id: 'JE', nombre: 'Jersey' },
    { id: 'JM', nombre: 'Jamaica' },
    { id: 'JO', nombre: 'Jordania' },
    { id: 'JP', nombre: 'Japón' },
    { id: 'KE', nombre: 'Kenia' },
    { id: 'KG', nombre: 'Kirguistán' },
    { id: 'KH', nombre: 'Camboya' },
    { id: 'KI', nombre: 'Kiribati' },
    { id: 'KM', nombre: 'Comoras' },
    { id: 'KN', nombre: 'San Cristóbal y Nieves' },
    { id: 'KP', nombre: 'Corea del Norte' },
    { id: 'KR', nombre: 'Corea del Sur' },
    { id: 'KV', nombre: 'Kosovo' },
    { id: 'KW', nombre: 'Kuwait' },
    { id: 'KY', nombre: 'Islas Caimán' },
    { id: 'KZ', nombre: 'Kazajistán' },
    { id: 'LA', nombre: 'Laos' },
    { id: 'LB', nombre: 'Líbano' },
    { id: 'LC', nombre: 'Santa Lucía' },
    { id: 'LI', nombre: 'Liechtenstein' },
    { id: 'LK', nombre: 'Sri lanka' },
    { id: 'LR', nombre: 'Liberia' },
    { id: 'LS', nombre: 'Lesoto' },
    { id: 'LT', nombre: 'Lituania' },
    { id: 'LU', nombre: 'Luxemburgo' },
    { id: 'LV', nombre: 'Letonia' },
    { id: 'LY', nombre: 'Libia' },
    { id: 'MA', nombre: 'Marruecos' },
    { id: 'MC', nombre: 'Mónaco' },
    { id: 'MD', nombre: 'Moldavia' },
    { id: 'ME', nombre: 'Montenegro' },
    { id: 'MF', nombre: 'San Martín (Francia)' },
    { id: 'MG', nombre: 'Madagascar' },
    { id: 'MH', nombre: 'Islas Marshall' },
    { id: 'MK', nombre: 'Macedónia' },
    { id: 'ML', nombre: 'Mali' },
    { id: 'MM', nombre: 'Birmania' },
    { id: 'MN', nombre: 'Mongolia' },
    { id: 'MO', nombre: 'Macao' },
    { id: 'MP', nombre: 'Islas Marianas del Norte' },
    { id: 'MQ', nombre: 'Martinica' },
    { id: 'MR', nombre: 'Mauritania' },
    { id: 'MS', nombre: 'Montserrat' },
    { id: 'MT', nombre: 'Malta' },
    { id: 'MU', nombre: 'Mauricio' },
    { id: 'MV', nombre: 'Islas Maldivas' },
    { id: 'MW', nombre: 'Malawi' },
    { id: 'MX', nombre: 'México' },
    { id: 'MY', nombre: 'Malasia' },
    { id: 'MZ', nombre: 'Mozambique' },
    { id: 'NA', nombre: 'Namibia' },
    { id: 'NC', nombre: 'Nueva Caledonia' },
    { id: 'NE', nombre: 'Niger' },
    { id: 'NF', nombre: 'Isla Norfolk' },
    { id: 'NG', nombre: 'Nigeria' },
    { id: 'NI', nombre: 'Nicaragua' },
    { id: 'NL', nombre: 'Países Bajos' },
    { id: 'NO', nombre: 'Noruega' },
    { id: 'NP', nombre: 'Nepal' },
    { id: 'NR', nombre: 'Nauru' },
    { id: 'NU', nombre: 'Niue' },
    { id: 'NZ', nombre: 'Nueva Zelanda' },
    { id: 'OM', nombre: 'Omán' },
    { id: 'PA', nombre: 'Panamá' },
    { id: 'PE', nombre: 'Perú' },
    { id: 'PF', nombre: 'Polinesia Francesa' },
    { id: 'PG', nombre: 'Papúa Nueva Guinea' },
    { id: 'PH', nombre: 'Filipinas' },
    { id: 'PK', nombre: 'Pakistán' },
    { id: 'PL', nombre: 'Polonia' },
    { id: 'PM', nombre: 'San Pedro y Miquelón' },
    { id: 'PN', nombre: 'Islas Pitcairn' },
    { id: 'PR', nombre: 'Puerto Rico' },
    { id: 'PS', nombre: 'Palestina' },
    { id: 'PT', nombre: 'Portugal' },
    { id: 'PW', nombre: 'Palau' },
    { id: 'PY', nombre: 'Paraguay' },
    { id: 'QA', nombre: 'Qatar' },
    { id: 'RE', nombre: 'Reunión' },
    { id: 'RO', nombre: 'Rumanía' },
    { id: 'RS', nombre: 'Serbia' },
    { id: 'RU', nombre: 'Rusia' },
    { id: 'RW', nombre: 'Ruanda' },
    { id: 'SA', nombre: 'Arabia Saudita' },
    { id: 'SB', nombre: 'Islas Salomón' },
    { id: 'SC', nombre: 'Seychelles' },
    { id: 'SD', nombre: 'Sudán' },
    { id: 'SE', nombre: 'Suecia' },
    { id: 'SG', nombre: 'Singapur' },
    { id: 'SH', nombre: 'Santa Elena, Ascensión y Tristán de Acuña' },
    { id: 'SI', nombre: 'Eslovenia' },
    { id: 'SJ', nombre: 'Svalbard y Jan Mayen' },
    { id: 'SK', nombre: 'Eslovaquia' },
    { id: 'SL', nombre: 'Sierra Leona' },
    { id: 'SM', nombre: 'San Marino' },
    { id: 'SN', nombre: 'Senegal' },
    { id: 'SO', nombre: 'Somalia' },
    { id: 'SR', nombre: 'Surinám' },
    { id: 'SS', nombre: 'República de Sudán del Sur' },
    { id: 'ST', nombre: 'Santo Tomé y Príncipe' },
    { id: 'SV', nombre: 'El Salvador' },
    { id: 'SX', nombre: 'Sint Maarten' },
    { id: 'SY', nombre: 'Siria' },
    { id: 'SZ', nombre: 'Swazilandia' },
    { id: 'TC', nombre: 'Islas Turcas y Caicos' },
    { id: 'TD', nombre: 'Chad' },
    { id: 'TF', nombre: 'Territorios Australes y Antárticas Franceses' },
    { id: 'TG', nombre: 'Togo' },
    { id: 'TH', nombre: 'Tailandia' },
    { id: 'TJ', nombre: 'Tayikistán' },
    { id: 'TK', nombre: 'Tokelau' },
    { id: 'TL', nombre: 'Timor Oriental' },
    { id: 'TM', nombre: 'Turkmenistán' },
    { id: 'TN', nombre: 'Tunez' },
    { id: 'TO', nombre: 'Tonga' },
    { id: 'TR', nombre: 'Turquía' },
    { id: 'TT', nombre: 'Trinidad y Tobago' },
    { id: 'TV', nombre: 'Tuvalu' },
    { id: 'TW', nombre: 'Taiwán' },
    { id: 'TZ', nombre: 'Tanzania' },
    { id: 'UA', nombre: 'Ucrania' },
    { id: 'UG', nombre: 'Uganda' },
    { id: 'UM', nombre: 'Islas Ultramarinas Menores de Estados Unidos' },
    { id: 'US', nombre: 'Estados Unidos de América' },
    { id: 'UY', nombre: 'Uruguay' },
    { id: 'UZ', nombre: 'Uzbekistán' },
    { id: 'VA', nombre: 'Ciudad del Vaticano' },
    { id: 'VC', nombre: 'San Vicente y las Granadinas' },
    { id: 'VE', nombre: 'Venezuela' },
    { id: 'VG', nombre: 'Islas Vírgenes Británicas' },
    { id: 'VI', nombre: 'Islas Vírgenes de los Estados Unidos' },
    { id: 'VN', nombre: 'Vietnam' },
    { id: 'VU', nombre: 'Vanuatu' },
    { id: 'WF', nombre: 'Wallis y Futuna' },
    { id: 'WS', nombre: 'Samoa' },
    { id: 'YE', nombre: 'Yemen' },
    { id: 'YT', nombre: 'Mayotte' },
    { id: 'ZA', nombre: 'Sudáfrica' },
    { id: 'ZM', nombre: 'Zambia' },
    { id: 'ZW', nombre: 'Zimbabue' }
];
let Pais = class Pais extends Vue {
    constructor() {
        super(...arguments);
        this.pais = { id: 'AR', nombre: 'Argentina' };
        this.paisesListado = paises;
    }
};
__decorate([
    Prop({ type: String, required: true })
], Pais.prototype, "name", void 0);
Pais = __decorate([
    Component({})
], Pais);
export default Pais;
