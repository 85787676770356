<script>
export default {
  props: {
    url: { type: String, required: true },
    show: { type: Boolean, required: true }
  },
  data() {
    return { provincias_json: false };
  },
  computed: {
    provincias() {
      if (!this.provincias_json) {
        let url =
          this.url +
          "/mkte_formas_pago/templates/form_todo_pago/provincias.json";
        axios.get(url).then(response => {
          this.provincias_json = response.data;
        });
      }
      return this.provincias_json;
    }
  }
};
</script>

<template>
  <div v-if="show" id="form_todo-pago">
    <div class="page-header">
      <h3>
        <i class="fa fa-shopping-cart"></i>
        <span v-html="'Informaci&oacute;n Complementaria'"></span>
      </h3>
    </div>

    <div class="panel panel-default panel-form">
      <div class="panel-body">
        <div class="row row-20">
          <div class="form-group col-xs-12 col-sm-6">
            <label class="control-label" v-html="'Direcci&oacute;n'">D</label>
            <input
              type="text"
              name="fpo_direccion"
              id="fpo_direccion"
              class="form-control input-sm"
              autocomplete="off"
              required
            />
          </div>

          <div class="form-group col-xs-12 col-sm-6">
            <label class="control-label" v-html="'Localidad'"></label>
            <input
              type="text"
              name="fpo_localidad"
              id="fpo_localidad"
              class="form-control input-sm"
              autocomplete="off"
              required
            />
          </div>

          <div class="form-group col-xs-12 col-sm-6">
            <label class="control-label" v-html="'C&oacute;digo Postal'"></label>
            <input
              type="text"
              name="fpo_codigo_postal"
              id="fpo_codigo_postal"
              class="form-control input-sm"
              autocomplete="off"
              required
            />
          </div>

          <div class="form-group col-xs-12 col-sm-6">
            <label class="control-label" v-html="'Provincia'"></label>
            <select name="fpo_provincia" id="fpo_provincia" class="form-control input-sm">
              <option
                v-for="(prv, key) in provincias"
                :key="key"
                :value="prv.codigo"
                v-html="prv.nombre"
              ></option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>